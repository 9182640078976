import React, { useEffect, useState } from "react";
import {
  Alert, Card, Form, Button, Spinner, OverlayTrigger, Tooltip, Accordion
} from "react-bootstrap";
import useModules from "../../../../hooks/useModules";

import logger from "../../../../utils/logger";

import example_1 from "../../../../assets/img/screenshots/services/bcd/breast_example_1.png";
import example_2 from "../../../../assets/img/screenshots/services/bcd/breast_example_2.png";

import * as Yup from "yup";
import { Formik } from "formik";
import useMessage from "../../../../hooks/useMessage";
import { useTranslation } from "../../../../hooks/useLocalization";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faFileArchive, faFileImage } from "@fortawesome/free-regular-svg-icons";

import { X, Check, Download, ChevronRight, ChevronDown } from "react-feather";

import JSZip from "jszip"
import HistoryTable from "./HistoryTable";

import AgreementModal from "../../../../modals/AgreementModal";
import TokensLeftModal from "../../../../modals/TokensLeftModal";
import useAuth from "../../../../hooks/useAuth";

const BCDModule = () => {
  logger.render(BCDModule);
  const { t, tt } = useTranslation();
  const { showMessage, showMessageWithDebug } = useMessage();

  const { bcdSend, isSubmitingProcess, isSubmitingResult, isFileResult } = useModules();

  const initialErrors = { info: null };

  // ---------------------------------------------------

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
      };
      reader.onerror = (error) => reject(error);
    });
  }

  const genSpinner = () => {
    const { hasError, errorMessage } = isSubmitingResult || false;
    if (isSubmitingProcess)
      return <>
        <Spinner animation="border" key="Warning" variant="warning" size="sm" style={{ minWidth: "18px", minHeight: "18px" }} />
      </>
    else if (!hasError)
      return <>
        <Check className="text-success" style={{ minWidth: "24px" }} />
      </>
    else
      return <>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              {t(errorMessage || "Something strange is happening")}
            </Tooltip>
          }
        >
          <X className="text-danger" style={{ minWidth: "24px" }} />
        </OverlayTrigger>

      </>
  }

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 500px)").matches
  )

  useEffect(() => {
    window
      .matchMedia("(min-width: 500px)")
      .addEventListener('change', e => setMatches(e.matches));
  }, []);

  const { user, getStatistic } = useAuth()

  const [oldTokens, setOldTokens] = useState(-1)
  const [hasSend, setHasSend] = useState(false)
  const [sendCount, setSendCount] = useState(0)
  const [isCompany, setCompany] = useState(true)

  useEffect(async () => {
    if (user?.company?.company_id) {
      setHasSend(true)
      setCompany(true)
      return
    }

    setCompany(false)
    setOldTokens(sendCount)
    const stats = await getStatistic();
    const { bcd } = stats;
    if (bcd === undefined) {
      setHasSend(true)
      setSendCount(10)
      return
    }
    if (bcd?.count < 10) {
      setHasSend(true)
      setSendCount(10 - bcd.count + bcd.tokens)
      return
    }
    if (bcd?.lastUse) {
      let val = new Date().getTime() - bcd?.lastUse
      if (val > 24 * 60 * 60 * 1000) {
        setHasSend(true)
        setSendCount(1)
        return
      }
    }
    if (bcd?.tokens > 0) {
      setHasSend(true)
      setSendCount(bcd?.tokens)
      return
    }
    setSendCount(0)
  }, [user, hasSend])

  const [hasAccess, setAlloyAccess] = useState(false)
  const [showModal, setShowModal] = useState(!hasAccess);

  useEffect(() => {
    setAlloyAccess(user?.agreement?.bcd?.value)
    setShowModal(!user?.agreement?.bcd?.value)
  }, [user])

  const [showTokensLeft, setTokensLeft] = useState(false);

  useEffect(() => {
    if (sendCount === 0 && oldTokens === 1) {
      setTokensLeft(true)
      setOldTokens(0)
    }
  }, [sendCount])

  const download = (file) => {
    // Create a new link
    try {
      const anchor = document.createElement('a');
      const path = URL.createObjectURL(file);
      anchor.href = path;
      anchor.download = file.name;

      // Append to the DOM
      document.body.appendChild(anchor);

      // Trigger `click` event
      anchor.click();

      // Remove element from DOM
      document.body.removeChild(anchor);
    } catch {
      return
    }
  };

  const [fileName, setFilename] = useState(null)

  return (
    <>
      <TokensLeftModal open={showTokensLeft} setOpen={setTokensLeft}
        title={""}
        text={t("We would like to inform you that the trial period...")}
      ></TokensLeftModal>
      <AgreementModal open={showModal} setOpen={setShowModal} service={"bcd"}
        title={t("Usage Agreement")}
        text={t("Welcome to our online platform for computerized...")} />
      <Card style={{ filter: (!hasAccess) ? "blur(3px)" : "" }}>
        <Card.Header>
          <h3 style={{ cursor: "default" }}>{t("Upload the archive in .zip format.")}</h3>
          <span style={{ cursor: "default" }}>{t("You can only upload 1 ...")}</span>
        </Card.Header>
        <Card.Body style={{ paddingTop: "0px" }}>
          <Accordion defaultActiveKey="-1" className="accordion-steps">
            <Accordion.Item eventKey="0">
              <Accordion.Header>{t("Example archive")}</Accordion.Header>
              <Accordion.Body className="bg-white">
                <div className={"d-flex " + ((!matches) ? "flex-column" : "align-items-center")}>
                  <div className="d-flex flex-column ps-1">
                    <FontAwesomeIcon icon={faFileArchive} size="10x" style={{ marginBottom: '.25rem' }} />
                    <span style={{ cursor: 'default' }}>{t("Archive Name")}</span>
                  </div>
                  <div>
                    {(!matches)
                      ? <ChevronDown size="100px" />
                      : <ChevronRight size="100px" />
                    }
                  </div>
                  <div className="d-flex flex-column align-icent pe-1">
                    <div className="d-flex align-items-center mb-2">
                      <FontAwesomeIcon icon={faFileImage} size="2x" style={{ marginRight: '.25rem' }} />
                      <span style={{ cursor: 'default' }}>R-MLO-file.dcm</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <FontAwesomeIcon icon={faFileImage} size="2x" style={{ marginRight: '.25rem' }} />
                      <span style={{ cursor: 'default' }}>L-MLO-file.dcm</span>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                      <FontAwesomeIcon icon={faFileImage} size="2x" style={{ marginRight: '.25rem' }} />
                      <span style={{ cursor: 'default' }}>R-CC-file.dcm</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faFileImage} size="2x" style={{ marginRight: '.25rem' }} />
                      <span style={{ cursor: 'default' }}>L-CC-file.dcm</span>
                    </div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>{t("Example files")}</Accordion.Header>
              <Accordion.Body className="bg-white" >
                <span style={{ cursor: "default" }}>{t("Please ensure you are archiving x-rays...")}</span>
                <div>
                  <img alt="example_1" src={example_1} className="m-3 ms-0 mw-100" />
                  <img alt="example_2" src={example_2} className="m-3 ms-0 mw-100" />
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>
      <Card style={{ filter: (!hasAccess) ? "blur(3px)" : "" }}>
        <Card.Body>
          <Formik
            // enableReinitialize
            initialValues={{
              patient_id: Math.floor(Math.random() * 99999 + 10000),
              file: null,
              anonymize: true
            }}
            initialErrors={initialErrors}
            validationSchema={
              Yup.object().shape({
                patient_id: Yup.number(),
                file: Yup.mixed(),
              })
            }
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              let isSubmitted = true
              try {
                if (!hasAccess)
                  throw new Error(t('Access denied'));
                if (!hasSend)
                  throw new Error(t("You're out of tokens"));

                let files = document.getElementById('sending_file_data').files;
                if (files.length === 0) throw new Error(t('Please, choose the file'));
                if (files[0].type !== "application/zip") {
                  if (files[0].name.split('.').pop() !== 'zip')
                    throw new Error(t('Please, choose the .zip file'));
                }
                let zip = new JSZip();
                await zip.loadAsync(files[0])
                  .then(function (file) {
                    let cnt = 0;
                    Object.keys(zip.files).forEach(function (filename) {
                      let file = zip.files[filename]
                      if (!file.dir) {
                        cnt++;
                        let split = filename.split('.')
                        // if (split.pop() !== 'dcm')
                        //   throw new Error(t('The archive should only contain .dcm files!'));
                        if (split[0].indexOf('_processed') > -1)
                          throw new Error(t('The archive contains processed files!'));
                        // TODO anonim
                      }
                    });
                    //if (cnt !== 4)
                    //  throw new Error(t('There should be 4 files in the archive!'));
                  })

                await bcdSend({ 'data': await toBase64(files[0]), patient_id: values.patient_id })
                values.patient_id++;
                setHasSend(false)
              } catch (error) {
                const { message = t("Something went wrong"), debug } = error;
                showMessageWithDebug({ error: message, debug: debug });
                isSubmitted = false
              } finally {
                if (isSubmitted) {
                  showMessage({ info: t("Process complete") })
                }
                setSubmitting(false);
                resetForm()
              }
            }}
          >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, }) => (
              <Form className="sign-in" onSubmit={handleSubmit}>
                {(errors.info || errors.submit) && (
                  <Alert
                    className="align-items-center"
                    variant={errors.info ? "success" : "danger"}
                  >
                    {errors.info && (
                      <div className="alert-icon">
                        <FontAwesomeIcon icon={faBell} fixedWidth />
                      </div>
                    )}
                    <div className="alert-message">
                      {errors.info || errors.submit}
                    </div>
                  </Alert>
                )}
                <div className={"d-flex " + ((!matches) ? "flex-column" : "")}>
                  <div className="d-flex mb-1">
                    <span className="d-flex align-items-center"
                      style={{ whiteSpace: "nowrap", cursor: "default" }}>{t("Case ID") + ": "}</span>
                    <Form.Control className={"ms-2 " + ((!matches) ? "" : "me-2")} style={{ maxWidth: (!matches) ? "" : "150px", minWidth: (!matches) ? "" : "100px" }}
                      required
                      id="patient_id"
                      type="number"
                      name="patient_id"
                      value={values.patient_id}
                      isInvalid={Boolean(
                        touched.patient_id && errors.patient_id
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={isSubmitingProcess || !hasAccess}
                      min={-1}
                    />
                  </div>

                  <div className="d-flex w-100 mb-1">
                    {
                      !(isSubmitingProcess || !hasAccess)
                        ? <Form.Label htmlFor="sending_file_data" className="w-100 form-control"
                          style={{ padding: "0", margin: "0", cursor: "pointer" }}
                          onMouseEnter={(e) => {
                            e.currentTarget.querySelector('#title').style['background-color'] = '#d8dce1'
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.querySelector('#title').style['background-color'] = '#e3e8ed'
                          }}
                        >
                          <Form.Group className="input-group m-0 p-0 d-flex w-100">
                            <div id='title' style={{ backgroundColor: "#e3e8ed", padding: "0.25rem 0.7rem", transitionDuration: ".2s" }}>{t("Select file")}</div>
                            <div style={{ padding: "0.25rem 0.7rem" }}>{(fileName?.length) ? fileName : t("File is not selected")}</div>
                          </Form.Group>
                        </Form.Label>
                        : <></>
                    }
                    <Form.Control
                      id="sending_file_data"
                      type="file"
                      name="file"
                      isInvalid={Boolean(
                        touched.file && errors.file
                      )}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        let elem = e.currentTarget;
                        setFilename((elem?.files?.length > 0) ? elem.files[0].name : null);
                        handleChange(e);
                      }}
                      disabled={isSubmitingProcess || !hasAccess}
                      accept=".zip"
                      style={!(isSubmitingProcess || !hasAccess) ? { visibility: "hidden", position: "absolute" } : {}}
                    />
                    <Button type={"submit"} variant="primary" disabled={isSubmitingProcess || !hasAccess || !hasSend} className="ms-2" style={{ minWidth: "100px", maxHeight: '30px' }}>
                      {t("Send")}
                    </Button>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <Form.Check
                    type="checkbox"
                    id="anonymize"
                    name="anonymize"
                    label={t("Anonymize metadata")}
                    value={values.anonymize}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    defaultChecked
                  />
                  {(!isCompany)
                    ? (hasSend)
                      ? <span className="text-success mb-1" style={{ cursor: "default", fontSize: "12px" }}>{tt("You have N tokens left", sendCount)}</span>
                      : <span className="text-danger mb-1" style={{ cursor: "default", fontSize: "12px" }}>{t("You're out of tokens")}</span>
                    : <></>
                  }
                </div>

              </Form>
            )}
          </Formik>
        </Card.Body>
        {(isSubmitingProcess || isSubmitingResult) ?
          <Card.Footer>
            <div className="d-flex align-items-center justify-content-between"
              style={{ borderStyle: 'solid none', borderWidth: '1px', borderColor: 'lightgray', minHeight: '40px' }}>
              {genSpinner()}
              <div className="w-100 ps-2 pe-2" style={{ overflow: "auto" }}>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="ps-1 pe-2" style={{ textWrap: "nowrap", cursor: "default" }}>{t("Case ID") + ": " + isSubmitingResult.patient_id}</td>
                      {(isSubmitingResult?.info?.length > 0) ?
                        <>
                          <td className="ps-1 pe-2" style={{ textWrap: "nowrap", cursor: "default" }}>{t("L-CC") + ": " + isSubmitingResult.info[0]["L-CC"]}</td>
                          <td className="ps-1 pe-2" style={{ textWrap: "nowrap", cursor: "default" }}>{t("R-CC") + ": " + isSubmitingResult.info[0]["R-CC"]}</td>
                          <td className="ps-1 pe-2" style={{ textWrap: "nowrap", cursor: "default" }}>{t("L-MLO") + ": " + isSubmitingResult.info[0]["L-MLO"]}</td>
                          <td className="ps-1 pe-2" style={{ textWrap: "nowrap", cursor: "default" }}>{t("R-MLO") + ": " + isSubmitingResult.info[0]["R-MLO"]}</td>
                          <td className="ps-1 pe-2" style={{ textWrap: "nowrap", cursor: "default" }}>{t("Delay") + ": " + isSubmitingResult.info[0].delay.split('.')[0] + " " + t("sec")}</td>
                        </>
                        : <></>
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
              {(isSubmitingProcess || isSubmitingResult?.hasError)
                ? <Button type="button" variant="outline-secondary" className="ms-2"
                  style={{ padding: ".25rem" }} disabled={true}> {<Download />}
                </Button>
                : (isFileResult)
                  ? <Button type="button" variant="primary" className="ms-2" style={{ padding: ".25rem" }}
                    onClick={() => { download(isFileResult) }}>
                    {<Download />}
                  </Button>
                  : <Button type="button" variant="outline-danger" className="ms-2"
                    style={{ padding: ".25rem" }} disabled={true}> {<Download />}
                  </Button>
              }
            </div>
          </Card.Footer>
          : <></>
        }
      </Card >
      <HistoryTable hasAccess={hasAccess} />
    </>
  );
};

export default BCDModule;
