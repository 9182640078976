import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./localization";
import routes from "./routes";

import { AuthProvider } from "./contexts/QDContext";
import { AnalyticsProvider } from "./contexts/dashboard/AnalyticsContext";
import { LocationProvider } from "./contexts/LocationContext";
import { UIProvider } from "./contexts/UIContext";
import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { ContextMenuProvider } from "./contexts/ContextMenuContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import { OffcanvasProvider } from "./contexts/OffcanvasContext";
import ChartJsDefaults from "./utils/ChartJsDefaults";
import { useTranslation } from "./hooks/useLocalization";
import { config } from "./config";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { FacebookProvider } from 'react-facebook';

const App = () => {
  const { t } = useTranslation();
  const content = useRoutes(routes);
  const google_clientId = config.social.google.clientId;
  const facebook_appId = config.social.facebook.appId;
  return (
    <HelmetProvider>
      <Helmet
        titleTemplate={`%s - ${t("QuMed Tool")}`}
        defaultTitle={t("QuMed Tool")}
      />
      <Provider store={store}>
        <GoogleOAuthProvider clientId={google_clientId}>
          <FacebookProvider appId={facebook_appId}>
            <LocationProvider>
              <ThemeProvider>
                <ContextMenuProvider>
                  <UIProvider>
                    <SidebarProvider>
                      <LayoutProvider>
                        <ChartJsDefaults />
                        <AuthProvider>
                          <AnalyticsProvider>
                            <OffcanvasProvider>
                              <NotificationProvider>
                                {content}
                              </NotificationProvider>
                            </OffcanvasProvider>
                          </AnalyticsProvider>
                        </AuthProvider>
                      </LayoutProvider>
                    </SidebarProvider>
                  </UIProvider>
                </ContextMenuProvider>
              </ThemeProvider>
            </LocationProvider>
          </FacebookProvider>
        </GoogleOAuthProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
