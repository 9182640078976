import { useCallback, useMemo } from "react";
import {
  Bell
} from "react-feather";
import { NOTIFICATION_STATUS } from "../../contexts/NotificationContext";
import { useTranslation } from "../../hooks/useLocalization";
import useNotifications from "../../hooks/useNotifications";
import NavbarDropdown from "./NavbarDropdown";

const NavbarNotifications = () => {
  const { notifications } = useNotifications();
  const { t } = useTranslation();
  const showBadge = useMemo(
    () =>
      notifications?.filter(({ status }) => status !== NOTIFICATION_STATUS.CLICKED)
        .length,
    [notifications]
  );
  const onToggle = useCallback(() => {
    notifications?.forEach(
      ({ onView }) => typeof onView === "function" && onView()
    );
  }, [notifications]);
  return (
    <NavbarDropdown
      notifications={notifications}
      header={
        notifications?.length
          ? t("Notifications")
          : t("You have no notifications")
      }
      icon={Bell}
      showBadge={showBadge}
      className="ms-auto cursor-arrow"
      onToggle={onToggle}
    >
    </NavbarDropdown>
  );
};

export default NavbarNotifications;
