import React from "react";
import { Outlet } from "react-router-dom";

import { AnalyticsProvider } from "../contexts/dashboard/AnalyticsContext";
import { ModulesProvider } from "../contexts/dashboard/ModulesContext";

import Wrapper from "../components/Wrapper";
import Main from "../components/Main";
import Content from "../components/Content";
import SidebarBody from "../components/sidebar/SidebarBody";
import JSBridge from "../components/JSBridge";
import Navbar from "../components/navbar/Navbar";

const Dashboard = ({ children }) => {
  return (
    <Wrapper>
      <AnalyticsProvider>
        <ModulesProvider>
          <SidebarBody />
          <Main>
            <Navbar />

            <Content>
              {children}
              <Outlet />
            </Content>

            {/* <Footer /> */}
          </Main>
        </ModulesProvider>
      </AnalyticsProvider>
      <JSBridge layout="Dashboard" />
      {/* <Settings /> */}
    </Wrapper>
  );
};

export default Dashboard;
