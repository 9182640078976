import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "../hooks/useLocalization";
import useMessage from "../hooks/useMessage";
import useAuth from "../hooks/useAuth";

const AgreementModal = ({ open, setOpen, title, service, text = "" }) => {
    const { t } = useTranslation();
    const { updateProfile } = useAuth();
    const [isSubmitting, setSubmitting] = useState();
    const { showMessageWithDebug } = useMessage();
    const onClose = async () => {
        setOpen(false);
    }
    return (
        <Modal show={open} onHide={onClose} enforceFocus={false}>
            <Helmet title={title || t("Info")} />
            <Modal.Header closeButton>{title || t("Info")}</Modal.Header>
            <Modal.Body className="text-center m-3">
                {text.split("\n").map((text, i) =>
                    <p key={i} style={{ textAlign: "justify" }}>{text}</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isSubmitting} variant="success"
                    onClick={async () => {
                        try {
                            setSubmitting(true);
                            let res = { agreement: {} }
                            res["agreement"][service] = {
                                date: new Date().toISOString(),
                                value: true
                            }
                            await updateProfile(res)
                        } catch (error) {
                            const { message = t("Something went wrong"), debug } = error;
                            showMessageWithDebug({ error: message, debug });
                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >
                    {t("Agree").toUpperCase()}
                </Button>{" "}
                <Button variant="secondary" onClick={onClose}>
                    {t("Quit").toUpperCase()}
                </Button>{" "}
            </Modal.Footer>
        </Modal>
    );
};

export default AgreementModal;
