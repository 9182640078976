import React, { useCallback, useEffect, useState } from "react";

import Sidebar from "./Sidebar";
import {
  PieChart,
  Target
} from "react-feather";
import { useTranslation } from "../../hooks/useLocalization";

const SidebarBody = (props) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  const analytics = useCallback(
    () => ({
      href: "/dashboard/analytics",
      title: t("Analytics"),
      icon: PieChart,
    }),
    [t]
  );

  const bcd = useCallback(
    () => ({
      href: "/dashboard/bcd",
      title: t("BCD"),
      icon: Target,
    }),
    [t]
  );

  const navItems = useCallback(
    () => {

      let pages = [];

      pages = [...pages, ...[bcd()]];
      pages = [...pages, ...[analytics()]];

      return [{ title: "", pages }];
    },
    [bcd, analytics]
  );

  useEffect(() => setItems(navItems()), [navItems]);

  return <Sidebar items={items} {...props} showFooter={true} />;
};

export default SidebarBody;
