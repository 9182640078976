import React, { useEffect } from "react";
import { useLocation, matchPath, useNavigate } from "react-router";
import api from "../../net/api";
import useAuth from "../../hooks/useAuth";
import useLocationContext from "../../hooks/useLocationContext";

function EntryGuard({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { deleteSearchParam } = useLocationContext();
  const { isInitialized, isAuthenticated, accessToken, user } = useAuth();
  const { isVerified } = user || {};

  // Resolve Settings
  // ------------------------
  useEffect(() => {
    if (isInitialized) {

      const isAuthenticated = !!(isVerified);
      const { pathname, search } = location;
      const params = new URLSearchParams(search);
      const target = params.get("target");
      const p_authToken = params.get("authToken");
      const p_accessToken = params.get("accessToken") || accessToken;
      deleteSearchParam(["target", "authToken", "accessToken"]);

      if (
        matchPath({ path: "/auth/sign-up", end: true }, pathname) ||
        matchPath({ path: "/auth/forgot-password", end: true }, pathname)
      ) {
        return;
      }

      if (target === api.AUTH.PASSWORD_RESTORE && p_authToken) {
        return navigate("/auth/reset-password", { replace: true });
      }

      if (target === api.AUTH.DELETE_CONFIRM && p_authToken) {
        return navigate("/auth/sign-xdelete-confirm", { replace: true });
      }

      if (isAuthenticated) {
        if (
          pathname === "" ||
          matchPath({ path: "auth", end: false }, pathname)
        ) {
          return navigate("/dashboard", { replace: true });
        }
        return;
      }

      if (target === api.AUTH.REGISTER_CONFIRM && p_accessToken) {
        if (p_authToken) {
          return navigate("/auth/sign-up-verify", { replace: true });
        }
      }

      if (target === "account_verify_fast" && p_accessToken) {
        if (isVerified) {
          return navigate("/auth/quick-sign-up", { replace: true });
        } else if (p_authToken) {
          return navigate("/auth/sign-up-verify/fast", { replace: true });
        }
      }

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, isAuthenticated, isVerified]);

  return <React.Fragment>{children}</React.Fragment>;
}

export default EntryGuard;
