import Chart from "react-apexcharts";
import { useTranslation } from "../../../../hooks/useLocalization";
import ReportCard from "./ReportCard";

const ReportServicesCard = ({ report, proPlan, isLoading }) => {
    const { t, tt } = useTranslation();
    return (
        <ReportCard
            variant="warning"
            helpText={t("Number of cases for each service")}
            proPlan={proPlan}
            childrenHeader={<>{t("Using services")}</>}
            childrenFooter={<span>{tt("Total N", report.total)}</span>}
            isLoading={isLoading}
        >
            <div className="chart chart-donut w-100">
                <Chart
                    options={report.options}
                    series={report.data}
                    type="pie"
                    height="200"
                />
            </div>
        </ReportCard>
    );
};

export default ReportServicesCard;
