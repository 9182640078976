/*eslint no-template-curly-in-string: "off"*/
/*eslint no-useless-computed-key: "off"*/
import { Case, Names } from "./languages";

const en = {
  translation: {
    // языки
    [Names.en]: Names.en,
    [Names.ua]: Names.ua,
    // валюта
    ["USD"]: "USD",
    ["N USD"]: "${1} USD",
    // счетчики
    ["K"]: "${1}K",
    ["M"]: "${1}M",
    ["N days"]: "${1} days",
    ["1 month"]: "1 month",
    ["N months"]: "${1} months",
    ["/mo"]: "${1}/mo",
    ["Price /mo"]: "${1}${2}/mo",
    ["Regular Price /mo"]: "Regular price: ${1}${2}/mo",
    // логин
    ["Welcome back"]: "Welcome back, ${1}",
    ["Sign In"]: "Sign In",
    ["Sign in"]: "Sign in",
    ["Sign in to your account"]: "Sign in to your account",
    ["Sign in to your account to continue"]:
      "Sign in to your account to continue",
    ["Your account has not been confirmed"]:
      "Your account has not been confirmed. Please check your email.",
    ["Must be a valid email"]: "Must be a valid email",
    ["Email"]: "Email",
    ["Enter your email"]: "Enter your email",
    ["Password"]: "Password",
    ["Enter your password"]: "Enter your password",
    ["Remember me next time"]: "Remember me next time",
    ["No Account?"]: "No Account?",
    ["Forgot password?"]: "Forgot password?",
    ["Login with Google"]: "Login with Google",
    ["Login with Facebook"]: "Login with Facebook",
    ["Sign up with Google"]: "Sign up with Google",
    ["Sign up with Facebook"]: "Sign up with Facebook",
    ["Login or password incorrect."]: "Login or password incorrect.",
    // регистрация
    ["Sign Up"]: "Sign Up",
    ["Sign up"]: "Sign up",
    ["Already have an account?"]: "Already have an account?",
    ["Login here"]: "Login here",
    ["You’re sign up!"]:
      "You’re sign up! Please check your email for the confirmation message we’ve just sent you.",
    ["Must be at least N characters"]: "Must be at least ${1} characters",
    ["Must contain only Latin characters"]: "Must contain only Latin characters, numbers, spaces, and _ ( ) symbols",
    ["Password must contain"]:
      "Must contain only Latin characters, as well as at least one number, one uppercase and one lowercase letter",
    ["Both password need to be the same"]: "Both password need to be the same",
    ["First name"]: "First name",
    ["Last name"]: "Last name",
    ["Email address"]: "Email address",
    ["Confirm password"]: "Confirm password",
    ["By clicking the Sign Up button"]:
      "By clicking the Sign Up button, you agree to our ${1}, and ${2}",
    ["Terms & Conditions"]: "Terms & Conditions",
    ["Terms & Conditions" + Case.Instrumental]: "Terms & Conditions",
    ["Privacy Policy"]: "Privacy Policy",
    ["Privacy Policy" + Case.Instrumental]: "Privacy Policy",
    ["Email confirmation"]: "Email confirmation",
    ["Please wait for the operation to complete"]:
      "Please wait for the operation to complete",
    ["Please, fill in the form to complete the registration"]:
      "Please, fill in the form to complete the registration",
    ["Company name"]: "Company name",
    ["Business website URL"]: "Business website URL",
    ["Number of employees"]: "Number of employees",
    ["Industry"]: "Industry",
    ["Your position"]: "Your position",
    ["Phone number"]: "Phone number",
    ["Save changes"]: "Save changes",
    ["Complete"]: "Complete",
    ["Please check your email"]: "Please check your email",
    ["Reset Password"]: "Reset Password",
    ["We have sent you an email with further instructions"]:
      "We have sent you an email with further instructions. Please follow the instructions to change your password.",
    ["We have sent you an email with further instructions.delete"]:
      "We have sent you an email with further instructions. Please follow the instructions to delete your account.",
    ["Email Address"]: "Email Address",
    ["Enter your email to reset your password"]:
      "Enter your email to reset your password",
    ["Remember your password?"]: "Remember your password?",
    ["Upload"]: "Upload",
    ["Display name"]: "Display name",
    ["Your changes have been successfully saved"]:
      "Your changes have been successfully saved",
    ["Note"]: "Note",
    ["Click on the message to see More Details"]:
      "Click on the message to see More Details",
    ["More details"]: "More details",
    ["Connection timeout"]: "Connection timeout",
    ["Reset your password"]: "Reset your password.",
    ["After deleting your account"]:
      "After deleting your account, you will not be able to sign in with this email until you register again",
    ["Confirm deletion"]: "Confirm deletion",
    // 404, 500 Error
    ["Error"]: "Error",
    ["Page not found"]: "Page not found.",
    ["The page you are looking for might have been removed"]:
      "The page you are looking for might have been removed.",
    ["Return to website"]: "Return to website",
    ["Internal server error"]: "Internal server error.",
    ["The server encountered something unexpected"]:
      "The server encountered something unexpected that didn't allow it to complete the request.",
    // профиль
    ["Account Settings"]: "Account Settings",
    ["Profile"]: "Profile",
    ["Details"]: "Details",
    ["User Profile"]: "User Profile",
    ["Additional Details"]: "Additional Details",
    ["Change Password"]: "Change Password",
    ["Delete Account"]: "Delete Account",
    ["Settings"]: "Settings",
    ["Payment history has been deleted"]: "Payment history has been deleted.",
    ["This feature is only available for test"]:
      "This feature is only available for test.",
    ["Clear payment history"]: "Clear payment history",
    ["Logout"]: "Logout",
    // уведомления
    ["Account details required"]: "Account details required",
    ["Please fill in all fields in the form"]:
      "Please fill in all fields in the form.",
    ["Notifications"]: "Notifications",
    ["You have no notifications"]: "You have no notifications",
    ["Reminder"]: "Reminder",
    // сеть
    ["Connection error"]: "Connection error",
    ["No data after loading"]: "No data after loading",
    ["Unsupported response data format"]: "Unsupported response data format",
    // разное
    ["Page"]: "Page",
    ["of"]: "of",
    ["N"]: "N",
    ["Select your service"]: "Select your service",
    ["Please, choose the file"]: "Please, choose the file",
    ["Please, choose the .zip file"]: "Please, choose the .zip archive",
    ["There should be 4 files in the archive!"]: "There should be 4 .dcm files in the archive!",
    ["The archive should only contain .dcm files!"]: "The archive should only contain .dcm files!",
    ["The archive contains processed files!"]: "The archive contains processed files!",
    ["Close"]: "Close",
    ["Usage Agreement"]: "Usage Agreement",
    ["Agree"]: "I Agree",
    ["Quit"]: "Quit",
    ["sec"]: "sec.",
    ["Refresh"]: "Refresh",
    ["Send a request"]: "Send a request",
    ["Ask me later"]: "Ask me later",
    ["Info"]: "Info",
    ["Last"]: "Last",
    // Analytics
    ["Analytics"]: "Analytics",
    ["Today"]: "Today",
    ["Yesterday"]: "Yesterday",
    ["Last Week"]: "Last Week",
    ["Last Month"]: "Last Month",
    ["Last Year"]: "Last Year",
    ["Custom"]: "Custom",
    ["Custom Date"]: "Custom",
    ["Total N"]: "Total: ${1}",
    ["Cases per month"]: "Cases per month",
    ["Total cases by month"]: "Total cases by month",
    /* Medical Services */
    ["BCD"]: "Breast Cancer Detection",
    ["Services"]: "Services",
    ["Cases"]: "Cases",
    ["Number of cases uploaded in the specified period"]: "Number of cases uploaded in the specified period",
    ["Using services"]: "Using services",
    ["Number of cases for each service"]: "The number of cases for each service during the specified period.",
    /* BCD */
    ["Example archive"]: "Example archive",
    ["Example files"]: "Example files",
    ["Archive Name"]: "ArchiveName.zip",
    ["DateTime"]: "Date and Time",
    ["Case ID"]: "Case ID",
    ["Delay"]: "Processing time",
    ["Send"]: "Send",
    ["History"]: "History",
    ["Please be advised that we do not retain uploaded data as files."]: "Please be advised that we do not retain uploaded data as files.",
    ["Upload the archive in .zip format."]: "Upload the archive in .zip format.",
    ["You can only upload 1 ..."]: "You can only upload 1 archive at a time. It should be a .zip archive containing 4 .dcm files with 4 projections.",
    ["Anonymize metadata"]: "Anonymize metadata",
    ["Data storage policy"]: "Data storage policy",
    ["Click to read more"]: "Click to read more",
    ["We would like to inform you about our updated data storage..."]: "We would like to inform you about our updated data storage policy regarding breast cancer computerized diagnostics. At Qudata, we prioritize your privacy and security.\nPlease be advised that we do not retain uploaded data as files. Our system is designed to process and analyze the information provided solely to generate case results. As part of our commitment to data security, we ensure that your uploaded data is not stored on our servers.\nFurthermore, users are encouraged to upload only their latest case results. Our system is optimized to process and provide insights based on the most recent information, streamlining the diagnostic process and minimizing storage requirements.",
    ["You don't have a history"]: "You don't have a history.",
    ["Welcome to our online platform for computerized..."]: "Welcome to our online platform for computerized breast cancer diagnostics. This system is designed for demo purposes only and should not be used for a diagnosis of any medical condition.\nOur platform showcases the AI radiology images analysis application to breast cancer screening. We aim to familiarize medical professionals with the innovative methods and techniques used in the field of breast cancer diagnostics. It is crucial to understand that the information presented on this platform is not a substitute for professional medical advice, diagnosis, or treatment.",
    ["Please ensure you are archiving x-rays..."]: "Please ensure you are archiving the following four files containing X-ray images (mammograms) before submission:",
    ["You're out of tokens"]: "You're out of attempts",
    ["You have N tokens left"]: "You have ${1} attempts left",
    ["Access denied"]: "Access denied",
    ["Select file"]: "Select file",
    ["File is not selected"]: "File is not selected",
    ["You have more entries in history"]: "You have more entries in history.",
    /* Reports */
    ["Activity Report"]: "Activity Report",
    ["Admin Reports"]: "Admin Reports",
    ["Title"]: "Title",
    ["Users"]: "Users",
    ["Download CSV"]: "Download CSV",
    ["Search"]: "Search",
    ["Enter company name..."]: "Enter company name...",
    ["company"]: "id",
    ["title"]: "Title",
    ["users"]: "User",
    ["cases"]: "Cases",
    // Закончились токены
    ["We would like to inform you that the trial period..."]: "We would like to inform you that the trial period for submitting X-ray images for testing has ended. We hope that the 10 available attempts have provided valuable insights into the capabilities of our diagnostic system.\nShould you wish to continue using our service and seamlessly integrate your medical organization with the QuData API, we kindly request you to contact us via email at $lsinfo@qudata.com$le. Our team will guide you through the integration process and address any queries or concerns you may have.\nAs a gesture of gratitude for your involvement, we would like to offer you access to one submission per day for ongoing testing of our service.\nThank you for considering QuData for breast cancer diagnostics. We look forward to further assisting you in this crucial endeavor.",
  },
};

export default en;
