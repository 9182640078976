import { atDevhost } from "../system/system";
import logger from "../utils/logger";
import provider from "./provider";
import { getTokens } from "./token";

// API
// ------------------------
class api {
  // Methods: Auth
  static AUTH = {
    GET: "account", // Получить информацию о профиле
    LOGIN: "login", // Авторизироваться
    SOCIAL: "social", // Авторизироваться в социальной сети
    UPDATE: "account_update", // Обновить информацию с профиле
    REGISTER: "signup", // Зарегистрироваться
    REGISTER_CONFIRM: "account_verify", // Выполнить проверку email с authToken
    REGISTER_FINALIZE: "account_complete", // Завершить регистрацию с дополнительной информацией о пользователе
    PASSWORD_FORGOT: "account_forgot", // Запустить процесс восстановления пароля через email
    PASSWORD_RESTORE: "account_restore", // Установить новый пароль с authToken
    DELETE: "account_unnecessary", // Запросить удаление пользователя
    DELETE_CONFIRM: "account_delete", // Удалить пользователя
    FETCH: "accounts_fetch", // Получить публичную информацию о пользователях
    VERIFY_FAST: "account_verify_fast", // Выполнить проверку email с authToken
    REGISTER_FAST: "account_complete_fast", // Завершить быструю регистрацию
    LICENSE: "license_update",
    ROLES: "roles_fetch",
    PERMISSIONS: "permissions_fetch",
    STATISTIC: "account_statistic",
  };

  // Methods: Permissions
  static ROLES = {
    SUPER_ADMIN: "Super Admin",
    ADMIN: "Admin",
    QA: "QA Engineer",
    ACC_MANAGER: "Account Manager",
  }

  // Methods: Bot analytics
  static REPORTS = {
    ANALYTICS: "getAnalytics", // Получить детали события
    HISTORY: "getHistory", // Получить историю
    SERVICES: "getServices", // Получить список сервисов
    GET_ACTIVITY_REPORT: "getActivityReport", // Получить список сервисов
  };

  static MODULES = {
    BCD: "BCD",
  };

  // Methods: Common
  static COMMON = {
    TOKENS: "headers", // Отправить заголовки авторизации
  };

  // Language
  static #language = "en";

  // Routes
  static #routes = null;
  static #tokens = {};
  static #initialized = false;
  static #authenticated = false;

  // Request
  static async request(method, data, headers = null, timeout = null) {
    const route = api.route(method);
    if (!route) {
      logger.warn(`[api] Invalid method (${method})`);
      return;
    }

    let lang = api.#language;
    let { server, socketID } = route;
    data = { ...data, lang };

    if (atDevhost()) {
      data.redirect = `${window.location.protocol}//${window.location.host}`;
    }

    if (typeof method === "object") {
      const { version, extension } = method;
      if (typeof version === "string") {
        server = server.replace(`\${version}`, version);
        data = { ...data, version };
      }
      if (typeof extension === "string") {
        server = server.replace(`\${extension}`, extension);
        data = { ...data, extension };
      }
    }

    timeout = timeout || 15_000;
    const options = { timeout };

    if (
      provider.isSocketConnecting(socketID) ||
      provider.isSocketConnected(socketID)
    ) {
      return await provider.socketSend(socketID, method, data, options);
    }

    //const headers = headers || api.tokens();
    return await provider.httpPOST(server, method, data, headers || api.tokens(), options);
  }

  // API config
  static configure(routes) {
    logger.net(`[api] routes=`, routes);

    api.#routes?.forEach(({ socketID }) => {
      api.enableSocket(socketID, false);
    });

    api.#routes = routes;
  }

  // API config tokens
  static configureToken(tokens) {
    if (Object.keys(tokens).some((t) => api.#tokens[t] !== tokens[t])) {
      api.#tokens = { ...api.#tokens, ...tokens };
      logger.net(`[api] tokens=`, api.tokens());
      api.#routes?.forEach(({ socketID }) => {
        api.sendTokens(socketID);
      });
    }
  }

  // API language
  static setLanguage(language) {
    api.#language = language;
  }

  // API auth ready
  static initialized(value) {
    if (api.#initialized === value) {
      return;
    }

    api.#routes
      .slice(0, 1)
      .forEach(({ socketID }) => api.enableSocket(socketID, value));
    api.#initialized = value;
  }

  // API ready
  static authenticated(value) {
    if (!api.#initialized || api.#authenticated === value) {
      return;
    }

    api.#routes
      .slice(1)
      .forEach(({ socketID }) => api.enableSocket(socketID, value));
    api.#authenticated = value;
  }

  // API enable socket & send tokens
  static enableSocket(socketID, value) {
    provider.enableSocket(socketID, value, {
      onopen: () => api.sendTokens(socketID),
    });
  }

  // API send tokens
  static sendTokens(socketID) {
    if (
      provider.isSocketConnecting(socketID) ||
      provider.isSocketConnected(socketID)
    ) {
      const tokens = api.tokens();
      if (!Object.keys(tokens).length) return; // No tokens yet
      provider.socketSend(socketID, api.COMMON.TOKENS, tokens);
    }
  }

  // API tokens
  static tokens() {
    return getTokens(api.#tokens);
  }

  // API tools
  static route(method) {
    return api.#routes?.find(({ methods }) => methods.indexOf(method) > -1);
  }

  // API ready
  static isReady() {
    return api.#initialized && api.#routes;
  }

  // API reset
  static reset() {
    api.initialized(false);
    api.authenticated(false);
  }

  static singleton() {
    if (typeof window.Qudata_api === "function") {
      window.Qudata_api.authenticated(false);
      window.Qudata_api.initialized(false);
    }
    return (window.Qudata_api = api);
  }
}

export default api = api.singleton();
