//import { useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { HelpCircle } from "react-feather";
import { useNavigate } from "react-router-dom";

const ReportCard = ({
  helpText,
  proPlan,
  children,
  childrenHeader,
  childrenFooter
}) => {
  const navigate = useNavigate();
  //const [hover, setHover] = useState(false);
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5">
          {childrenHeader}
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{helpText}</Tooltip>}
          >
            <a
              target="_blank"
              rel="noreferrer"
              href="/"
              onClick={(e) => e.preventDefault()}
              className="nav-link nav-icon ms-2"
            >
              <HelpCircle className="feather" />
            </a>
          </OverlayTrigger>
        </Card.Title>
      </Card.Header>
      <div
        className={proPlan ? "chart-locked cursor-pointer" : ""}
        onClick={() => {
          if (proPlan) {
            navigate(`/account/pricing#${proPlan.index}`);
          }
        }}
      >
        <Card.Body className="p-0">{children}</Card.Body>
        <Card.Body>{childrenFooter}</Card.Body>
      </div>
    </Card>
  );
};

export default ReportCard;
