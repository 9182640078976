import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "../hooks/useLocalization";
import { config } from "../config";

const TokensLeftModal = ({ open, setOpen, title, text = "" }) => {
    const { t } = useTranslation();
    const onClose = async () => {
        setOpen(false);
    }
    return (
        <Modal show={open} onHide={onClose} enforceFocus={false}>
            <Helmet title={title || t("Info")} />
            <Modal.Header closeButton>{title || t("Info")}</Modal.Header>
            <Modal.Body className="text-center m-3">
                {text.split("\n").map((text, i) => {
                    let ls = text.indexOf('$ls');
                    let le = text.indexOf('$le');
                    if (ls > -1 && le > -1) {
                        let link = text.substring(ls + 3, le)
                        return <p key={i} style={{ textAlign: "justify" }}>
                            {text.substring(0, ls)}
                            <a href={"mailto:" + link}>{link}</a>
                            {text.substring(le + 3)}
                        </p>
                    }
                    return <p key={i} style={{ textAlign: "justify" }}>{text}</p>
                }

                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success"
                    onClick={async () => { window.location = "mailto:" + config.links?.support }}
                >
                    {t("Send a request")}
                </Button>{" "}
                <Button variant="secondary" onClick={onClose}>
                    {t("Ask me later")}
                </Button>{" "}
            </Modal.Footer>
        </Modal>
    );
};

export default TokensLeftModal;
