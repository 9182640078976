const DATE_FORMAT = {
  MONTH: "MM/yy",
  DATE: "dd/MM/yy",
  HOUR: "HH:mm",
};

const locales = [{
  name: 'en',
  options: {
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    toolbar: {
      download: 'Download',
      selection: 'Selection',
      selectionZoom: 'Selection Zoom',
      zoomIn: 'Zoom In',
      zoomOut: 'Zoom Out',
      pan: 'Panning',
      reset: 'Reset Zoom',
      exportToSVG: 'Download SVG',
      exportToPNG: 'Download PNG',
      exportToCSV: 'Download CSV',
    }
  }
},
{
  name: 'uk',
  options: {
    months: ['Січень', 'Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'],
    shortMonths: ['Січ', 'Лют', 'Бер', 'Кві', 'Тра', 'Чер', 'Лип', 'Сер', 'Вер', 'Жов', 'Лис', 'Гру'],
    days: ['Неділя', 'Понеділок', 'Вівторок', 'Середа', 'Четвер', 'П\'ятниця', 'Субота'],
    shortDays: ['Нед', 'Пон', 'Вів', 'Сер', 'Чет', 'П\'ят', 'Суб'],
    toolbar: {
      download: 'Завантажити',
      selection: 'Вибір',
      selectionZoom: 'Масштаб виділення',
      zoomIn: 'Збільшити',
      zoomOut: 'Зменшити',
      pan: 'Панорамування',
      reset: 'Скинути масштаб',
      exportToSVG: 'Завантажити SVG',
      exportToPNG: 'Завантажити PNG',
      exportToCSV: 'Завантажити CSV',
    }
  }
}]

const parseRows = (rows = [], { format }) => {
  return rows.map((row) => ({
    ...row,
    time: format === DATE_FORMAT.DATE ? row.time.split("T")[0] : row.time,
  }));
};

const tooltipFormat = ({ format }) => {
  return format === DATE_FORMAT.DATE ? "dd MMM yyyy" : (format === DATE_FORMAT.MONTH) ? "MMM yyyy" : "HH:mm";
};

const parseAnalytics = (rows, props, lang = 'en') => {
  const { chartName, filename, format = DATE_FORMAT.DATE } = props;
  rows = parseRows(rows, props);
  // const palette = usePalette();

  const data = [
    {
      name: chartName || "",
      data: rows.map(({ count }) => count),
    },
  ];

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        export: {
          csv: { filename },
          png: { filename },
          svg: { filename },
        },
      },
      defaultLocale: lang,
      locales: locales
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
      hover: { size: 6 },
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "datetime",
      tooltip: { enabled: false },
      categories: rows.map(({ time }) => time),
      labels: {
        format,
        datetimeUTC: false,
        showDuplicates: true
      },
    },
    yaxis: {
      labels: { formatter: (value) => value.toFixed(0) },
    },
    tooltip: {
      x: {
        format: tooltipFormat({ format }),
      },
    },
    colors: [/*palette.primary*/ "#3B82EC"],
  };

  const total = rows.reduce((p, { count: c }) => p + c, 0) | 0;
  return { data, options, total };
};

const parsePerMonth = (rows, props, lang = 'en') => {
  const format = DATE_FORMAT.MONTH;
  const { chartName, filename } = props;
  rows = parseRows(rows, props);
  // const palette = usePalette();

  const data = [
    {
      name: chartName || "",
      data: rows.map(({ count }) => count),
    },
  ];

  const options = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        export: {
          csv: { filename },
          png: { filename },
          svg: { filename },
        },
      },
      defaultLocale: lang,
      locales: locales
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
      hover: { size: 6 },
    },
    stroke: {
      curve: "straight",
    },
    xaxis: {
      type: "datetime",
      tooltip: { enabled: false },
      categories: rows.map(({ time }) => time),
      labels: {
        format,
        datetimeUTC: false,
        showDuplicates: false
      },
    },
    yaxis: {
      labels: { formatter: (value) => value.toFixed(0) },
    },
    tooltip: {
      x: {
        format: tooltipFormat({ format }),
      },
    },
    colors: [/*palette.primary*/ "#3B82EC"],
  };

  const total = rows.reduce((p, { count: c }) => p + c, 0) | 0;
  return { data, options, total };
};

const parseServices = (rows, props, lang = 'en') => {
  const { filename } = props;
  const data = rows.map(({ count }) => count);

  const options = {
    chart: {
      toolbar: {
        export: {
          csv: { filename },
          png: { filename },
          svg: { filename },
        },
      },
      defaultLocale: lang,
      locales: locales
    },
    labels: rows.map(({ service }) => '' + service),
    theme: { monochrome: { enabled: false } },
    legend: {
      position: 'left',
      formatter: function (legendName, opts) {
        let value = 0;
        let values = opts.w.globals.initialSeries;
        let labels = opts.w.globals.labels;
        for (let i = 0; i < labels.length; i++) {
          if (labels[i] === legendName) {
            value = values[i];
          }
        }
        return legendName + ": " + value
      },
    },
    plotOptions: {
      pie: { expandOnClick: false }
    }
  };

  const total = rows.reduce((p, { count: c }) => p + c, 0) | 0;
  return { data, options, total };
};


export {
  DATE_FORMAT,
  parseAnalytics,
  parseServices,
  parsePerMonth,
};
