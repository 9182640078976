import React, { useEffect, useState } from "react";
import {
    Card, OverlayTrigger, Tooltip, ProgressBar, Form, Button
} from "react-bootstrap";

import AntiSpam from "../../../../utils/spam";

import useAnalytics from "../../../../hooks/useAnalytics";

import useModules from "../../../../hooks/useModules";

import logger from "../../../../utils/logger";

import { useTranslation } from "../../../../hooks/useLocalization";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faBell, faFileArchive, faFileImage } from "@fortawesome/free-regular-svg-icons";

import ShowInfoModal from "../../../../modals/ShowInfoModal";

import { Info, RefreshCw, RotateCw } from "react-feather";

const antiSpam = new AntiSpam();

const HistoryTable = ({ hasAccess, style }) => {
    logger.render(HistoryTable);
    const { t } = useTranslation();

    const { reports = {}, isLoading = {}, getHistory } = useAnalytics();
    const { history } = reports;

    const { analytics: dataIsLoaded } = isLoading;

    const { isSubmitingProcess, isSubmitingResult } = useModules();

    // --------------------- Threading -------------------

    const refresh = async () => await getHistory({ field: 'service', value: 'bcd' });

    useEffect(() => {
        antiSpam.action(async () => {
            await refresh()
        });
    }, []);

    const [lastN, setLastN] = useState(15)
    const [customSelected, setLastNSelected] = useState(false)

    const isLoadingAny = Object.keys(isLoading).some(
        (key) => isLoading[key] === true
    );

    const [showModal, setShowModal] = useState();
    return <>
        <ShowInfoModal open={showModal} setOpen={setShowModal}
            title={t("Data storage policy")}
            text={t("We would like to inform you about our updated data storage...")} />
        <Card style={{ filter: (!hasAccess) ? "blur(3px)" : "", ...style }}>
            <Card.Header>
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <h3 className="pe-1" style={{ cursor: "default" }}>{t("History")}</h3>
                        {(hasAccess)
                            ? <OverlayTrigger placement="bottom" overlay={
                                <Tooltip>
                                    <span style={{ cursor: "default" }}>{t("Click to read more")}</span>
                                </Tooltip>
                            } >
                                <Info className="ps-1 pe-1" style={{ cursor: "help" }} onClick={() => setShowModal(true)} />
                            </OverlayTrigger>
                            : <Info className="ps-1 pe-1" />}
                    </div>
                    <div className="d-flex">
                        <Form.Group>
                            <Form.Select
                                style={{ minWidth: "150px" }}
                                value={(customSelected) ? -1 : lastN}
                                onChange={(e) => {
                                    let value = e.currentTarget.value;
                                    setLastN((value > 0) ? value : 15);
                                    setLastNSelected(e.currentTarget.value < 0)
                                }}
                            >
                                {[15, 30, 50, 100].map((N, i) =>
                                    <option value={N} key={i}>{t('Last') + ' ' + N}</option>
                                )}
                                <option value="-1">{t("Custom")}</option>
                            </Form.Select>
                        </Form.Group>
                        {(customSelected)
                            ? <Form.Control
                                type="number"
                                min={0}
                                max={150}
                                style={{ maxWidth: "150px" }}
                                className="ms-1"
                                value={lastN}
                                onChange={(e) => { setLastN(e.currentTarget.value) }}
                            />
                            : <></>
                        }
                        <Button
                            variant="outline-primary"
                            className="shadow-sm ms-1"
                            disabled={isLoadingAny}
                            style={{ whiteSpace: "nowrap" }}
                            onClick={refresh}
                        >
                            {isLoadingAny ? (
                                <RotateCw className="feather fa-spin" />
                            ) : (
                                <RefreshCw className="feather" />
                            )}
                            <span className="ms-2">{t("Refresh")}</span>
                        </Button>
                    </div>
                </div>
                <div className="d-flex">
                    <span style={{ cursor: "default" }}>{t("Please be advised that we do not retain uploaded data as files.")}</span>
                </div>
                <ProgressBar
                    className={`mt-2 ${isLoadingAny ? "" : "opacity-0"}`}
                    style={{ height: '4px' }}
                    default animated variant="primary"
                    now={isLoadingAny ? 90 : 0}
                />
            </Card.Header>
            <Card.Body style={{ overflow: "auto" }}>
                {(history?.length || isSubmitingProcess || isSubmitingResult)
                    ? (dataIsLoaded !== undefined)
                        ? (history?.length > 0)
                            ? <>
                                <table className="w-100">
                                    <tbody>
                                        <tr>
                                            <th style={{ textAlign: "center", cursor: "default", padding: ".5rem" }}>{t("N")}</th>
                                            <th style={{ textAlign: "center", cursor: "default", padding: ".5rem" }}>{t("DateTime")}</th>
                                            <th style={{ textAlign: "center", cursor: "default", padding: ".5rem" }}>{t("Case ID")}</th>
                                            <th style={{ textAlign: "center", cursor: "default", padding: ".5rem" }}>{t("L-CC")}</th>
                                            <th style={{ textAlign: "center", cursor: "default", padding: ".5rem" }}>{t("R-CC")}</th>
                                            <th style={{ textAlign: "center", cursor: "default", padding: ".5rem" }}>{t("L-MLO")}</th>
                                            <th style={{ textAlign: "center", cursor: "default", padding: ".5rem" }}>{t("R-MLO")}</th>
                                            <th style={{ textAlign: "center", cursor: "default", padding: ".5rem" }}>{t("Delay")}</th>
                                        </tr>
                                        {
                                            history.map((item, i) => {
                                                return (i < lastN)
                                                    ? <tr key={i}>
                                                        <td style={{ textAlign: "center", cursor: "default", padding: ".25rem" }}>{i + 1}</td>
                                                        <td style={{ textAlign: "center", cursor: "default", padding: ".25rem" }}>{item.time}</td>
                                                        <td style={{ textAlign: "center", cursor: "default", padding: ".25rem" }}>{item.patient_id}</td>
                                                        <td style={{ textAlign: "center", cursor: "default", padding: ".25rem" }}>{item["L-CC"]}</td>
                                                        <td style={{ textAlign: "center", cursor: "default", padding: ".25rem" }}>{item["R-CC"]}</td>
                                                        <td style={{ textAlign: "center", cursor: "default", padding: ".25rem" }}>{item["L-MLO"]}</td>
                                                        <td style={{ textAlign: "center", cursor: "default", padding: ".25rem" }}>{item["R-MLO"]}</td>
                                                        <td style={{ textAlign: "center", cursor: "default", padding: ".25rem" }}>{item.delay + " " + t('sec')}</td>
                                                    </tr>
                                                    : <tr key={i} style={{ display: 'none' }}></tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                {(history.length > lastN)
                                    ? <><hr /><span className="mt-3" style={{ cursor: "default" }}>{t("You have more entries in history") + ".."}</span></>
                                    : <></>}
                            </>
                            : <span style={{ cursor: "default" }}>{t("You don't have a history")}</span>
                        : <></>
                    : <span style={{ cursor: "default" }}>{t("You don't have a history")}</span>
                }
            </Card.Body>
        </Card >
    </>
}

export default HistoryTable;