import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";

import { useLocation } from "react-router";
import SignUpComplete from "../../components/auth/SignUpComplete";
import ForgotPassword from "../../components/auth/ForgotPassword";
import SignXProfile from "../../components/auth/SignXProfile";
import SignXDelete from "../../components/auth/SignXDelete";
import { useTranslation } from "../../hooks/useLocalization";

import ActivityReport from "../../components/tables/CompanyActivityReport";

import api from "../../net/api";

import useAuth from "../../hooks/useAuth";

const Navigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { hash } = location;
  const { checkRole } = useAuth();
  return <>
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Account Settings")}
        </Card.Title>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item tag="a" href="#public" action active={showPublic(hash)}>
          {t("Profile")}
        </ListGroup.Item>
        <ListGroup.Item
          tag="a"
          href="#private"
          action
          active={showPrivate(hash) || showPrivateH(hash)}
        >
          {t("Details")}
        </ListGroup.Item>
        <ListGroup.Item
          tag="a"
          href="#password"
          action
          active={showPassword(hash)}
        >
          {t("Password")}
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#delete" action active={showDelete(hash)}>
          {t("Delete Account")}
        </ListGroup.Item>
      </ListGroup>
    </Card>
    {(checkRole(api.ROLES.SUPER_ADMIN) || checkRole(api.ROLES.ACC_MANAGER)) && (
      <>
        <h5 style={{ cursor: "default" }}>{t("Admin Reports")}</h5>
        <Card>
          <ListGroup variant="flush">
            <ListGroup.Item tag="a" href="#activity"
              action active={showActivity(hash)}
            >
              {t("Activity Report")}
            </ListGroup.Item>
          </ListGroup>
        </Card>
      </>
    )}

  </>;
};

const PublicInfo = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("User Profile")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <SignXProfile dashboard />
      </Card.Body>
    </Card>
  );
};


const PrivateInfo = () => {
  const location = useLocation();
  const hightlight = showPrivateH(location.hash);

  return (
    <Card>
      <Card.Header> </Card.Header>
      <Card.Body>
        <SignUpComplete dashboard hightlight={hightlight} />
      </Card.Body>
    </Card>
  );
};

const ChangePassword = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Change Password")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <ForgotPassword dashboard />
      </Card.Body>
    </Card>
  );
};

const DeleteAccount = () => {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h5" className="mb-0">
          {t("Delete Account")}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <SignXDelete dashboard />
      </Card.Body>
    </Card>
  );
};

const showPublic = (hash) => hash === "" || hash === "#public";
const showPrivate = (hash) => hash === "#private";
const showPrivateH = (hash) => hash === "#private!";
const showPassword = (hash) => hash === "#password";
const showDelete = (hash) => hash === "#delete";
const showActivity = (hash) => hash === "#activity";

const Settings = () => {
  const { checkRole } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const { hash } = location;
  return (
    <React.Fragment>
      <Helmet title={t("Settings")} />
      <Container fluid className="p-0">
        {/* <h1 className="h3 mb-3">Settings</h1> */}
        <Row>
          <Col md="3" xl="2">
            <Navigation />
          </Col>
          <Col md="9" xl="10">
            {showPublic(hash) && <PublicInfo />}
            {(showPrivate(hash) || showPrivateH(hash)) && <PrivateInfo />}
            {showPassword(hash) && <ChangePassword />}
            {showDelete(hash) && <DeleteAccount />}
            {showActivity(hash) && (checkRole(api.ROLES.SUPER_ADMIN) || checkRole(api.ROLES.ACC_MANAGER)) && <ActivityReport />}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Settings;
