import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";
import JSONViewer from "../components/JSONViewer";

const logLevelVariant = (level) =>
  (level === "error" && "danger") ||
  (level === "debug" && "secondary") ||
  ((level === "warning" || level === "info") && level) ||
  "primary";

const DebugLogsModal = ({ debug }) => {
  if (debug) {
    const { logs } = debug;
    if (Array.isArray(logs) && logs.length) {
      return logs.map(({ level, text }, index) => (
        <Alert variant={logLevelVariant(level)} key={index}>
          <div className="alert-icon">
            <FontAwesomeIcon icon={faBell} fixedWidth />
          </div>
          <div className="alert-message">{text}</div>
        </Alert>
      ));
    }
    return <JSONViewer json={debug} />;
  }
  return null;
};

export default DebugLogsModal;
