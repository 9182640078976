const FILTERS = {
  GROUP: "group",
  INTERVAL: "interval",
  STAMPTERVAL: "stampterval",
  INTERVAL_VALUES: "interval_values",
  CHANNELS: "channels",
  EVENT_NAME: "eventName",
};

const INTERVAL = {
  TODAY: "today",
  YESTERDAY: "yesterday",
  LAST_WEEK: "lastweek",
  LAST_MONTH: "lastmonth",
  LAST_YEAR: "lastyear",
  CUSTOM: "custom",
};

const createInterval = (type, values) => {
  let from = new Date();
  let to = new Date();
  switch (type) {
    case INTERVAL.TODAY:
      // И так всё норм
      break;
    case INTERVAL.YESTERDAY:
      from.setDate(from.getDate() - 1);
      to.setDate(to.getDate() - 1);
      break;
    case INTERVAL.LAST_WEEK:
      from.setDate(from.getDate() - 6);
      break;
    case INTERVAL.LAST_MONTH:
      from.setDate(from.getDate() - 31);
      break;
    case INTERVAL.LAST_YEAR:
      from.setFullYear(from.getFullYear() - 1, from.getMonth(), from.getDate())
      break;
    case INTERVAL.CUSTOM:
      if (values?.length === 2) {
        from = values[0];
        to = values[1];
      }
      break;
    default:
    // logger.log(`Unsupported interval type "${type}"`);
  }

  from.setHours(0);
  from.setMinutes(0);
  from.setSeconds(0);

  to.setHours(23);
  to.setMinutes(59);
  to.setSeconds(59);

  return [from, to];
};

const dateToString = (date, dividor = "-", useTime = false) => {
  if (!(date instanceof Date)) {
    return `invalid-date`;
  }
  var dd = date.getDate();
  var mm = date.getMonth() + 1; // January is 0!
  var yyyy = date.getFullYear();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  if (useTime) {
    let hh = date.getHours();
    let MM = date.getMinutes();
    let ss = date.getSeconds();
    return yyyy + dividor + mm + dividor + dd + hh + MM + ss;
  } else
    return yyyy + dividor + mm + dividor + dd;
};

export { FILTERS, INTERVAL, createInterval, dateToString };
