import logger from "../../utils/logger";

import { Button } from "react-bootstrap";

import { useTranslation } from "../../hooks/useLocalization";

import { useLogin } from 'react-facebook';
import iconFacebook from "../../assets/img/brands/facebook.svg";

function FacebookButton({ callback, setSocialIn, scopes, disabled }) {
    logger.render(FacebookButton);
    const { t } = useTranslation();

    const { login, isLoading } = useLogin();
    async function handleLogin() {
        setSocialIn(true)
        try {
            const response = await login({ scope: scopes });
            const { authResponse } = response;
            callback(authResponse);
        } catch (error) {
            console.log(error.message);
        }
    }

    return (
        <Button className="w-100" type="submit" variant="outline-primary" size="lg"
            disabled={isLoading || disabled} onClick={handleLogin} >
            <img
                alt="Facebook"
                src={iconFacebook}
                width="22"
                style={{ marginRight: "0.4rem" }}
            />
            {t("Login with Facebook")}
        </Button>
    );
};

export default FacebookButton;