import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
//import useMyFacebook from "../../hooks/useMyFacebook";
import { useTranslation } from "../../hooks/useLocalization";
import useLocationContext from "../../hooks/useLocationContext";
import { useGoogleLogin } from "@react-oauth/google";
import iconGoogle from "../../assets/img/brands/google.svg";
import InputPassword from "../InputPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import logger from "../../utils/logger";
import { config } from "../../config";
import axiosInstance from "../../net/axios";

// import { FacebookProvider } from 'react-facebook';
import FacebookButton from "./FacebookButton";
//import FacebookContext from "./FacebookContext";

//import { LoginFacebook } from './facebook/LoginFacebook'
//import { FacebookButton } from './facebook/FacebookButton'

//import iconFacebook from "../../assets/img/brands/facebook.svg";

function SignIn() {
  logger.render(SignIn);
  const { t } = useTranslation();
  const submitRef = useRef();
  const navigate = useNavigate();
  const { applyRedirect } = useLocationContext();
  const [isSocialIn, setSocialIn] = useState(false);
  const { signIn, googleIn, user } = useAuth();
  const [state, setState] = useState({
    passwordTyped: false,
    passwordHidden: true,
    socialIn: null,
  });

  const { facebookIn } = useAuth();

  const emailRef = useRef();
  useEffect(() => emailRef.current?.focus(), [emailRef]);

  const google_clientId = config.social.google.clientId;
  // const facebook_appId = config.social.facebook.appId;
  const facebook_fields = config.social.facebook.fields;

  const togglePasswordEye = () => {
    const passwordHidden = !state.passwordHidden;
    setState({ ...state, passwordHidden });
  };

  const socialResponse = (handler, args) => {
    setState({ ...state, socialIn: { handler, args } });
    document.getElementById('login').value = '';
    submitRef.current?.dispatchEvent(new MouseEvent("click"));
  };

  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      logger.dev("[Google]", response);
      const { error, access_token: accessToken } = response;
      if (error || !accessToken) {
        setSocialIn(false);
        return;
      }

      const userInfo = await axiosInstance.get(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        { headers: { Authorization: `Bearer ${response.access_token}` } },
      );

      const { email, given_name: firstName, family_name: lastName } = userInfo.data;
      socialResponse(googleIn, [accessToken, email, firstName, lastName]);
    },

    onError: errorResponse => console.log(errorResponse),
  });

  const [localEmail, setLocalEmail] = useState()
  const [localCheck, setLocalCheck] = useState(true)

  useEffect(() => {
    setLocalEmail(localStorage.email);
    setLocalCheck((localStorage.check === 'true') ? true : false)
  })

  /* Facebook --------------------------------------------------------- */

  const responseFacebook = (response = {}) => {
    logger.dev("[Facebook]", response);
    const {
      error,
      accessToken
    } = response;
    if (error || !accessToken) {
      setSocialIn(false);
      return;
    }
    socialResponse(facebookIn, [accessToken]);
  };

  /* Facebook --------------------------------------------------------- */

  const initialErrors = {
    info:
      user && !user.isVerified
        ? t("Your account has not been confirmed")
        : null,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ email: user?.email || localEmail || "", password: "", submit: false }}
      initialErrors={initialErrors}
      validationSchema={
        !isSocialIn
          ? Yup.object().shape({
            email: Yup.string().email(t("Must be a valid email")).max(150),
            password: Yup.string().max(50),
          })
          : null
      }
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {

          const { socialIn } = state;
          if (isSocialIn && socialIn) {
            await socialIn.handler(...socialIn.args);
          } else {
            let check = document.getElementById('rememberMe')?.checked;
            localStorage.email = (check) ? values.email : null;
            await signIn(values.email, values.password);
          }
          setSocialIn(false);
          const redirected = applyRedirect("dashboard", { clear: true });
          if (!redirected) {
            navigate("/dashboard");
          }
        } catch (error) {
          const { code, message = t("Something went wrong") } = error;
          if (code === 12) {
            setErrors({ info: message });
          } else {
            setErrors({ submit: message });
          }
          setStatus({ success: false });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form className="sign-in" onSubmit={handleSubmit}>
          {(errors.info || errors.submit) && (
            <Alert
              className="align-items-center"
              variant={errors.info ? "success" : "danger"}
            >
              {errors.info && (
                <div className="alert-icon">
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
              )}
              <div className="alert-message">
                {errors.info || errors.submit}
              </div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label htmlFor="login">{t("Email")}</Form.Label>
            <Form.Control
              required={!isSocialIn}
              size="lg"
              id="login"
              type="email"
              name="email"
              autoComplete="username"
              placeholder={t("Enter your email")}
              ref={emailRef}
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="password">{t("Password")}</Form.Label>
            <InputPassword
              eye={{
                hidden: !state.passwordTyped,
                passwordHidden: state.passwordHidden,
                onToggle: togglePasswordEye,
              }}
              required={!isSocialIn}
              size="lg"
              id="password"
              type={state.passwordHidden ? "password" : "text"}
              name="password"
              autoComplete="current-password"
              placeholder={t("Enter your password")}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={(e) => {
                const passwordTyped = true;
                setState({ ...state, passwordTyped });
                handleChange(e);
              }}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <div>
            <Form.Check
              type="checkbox"
              id="rememberMe"
              label={t("Remember me next time")}
              checked={localCheck}
              onChange={(e) => {
                setLocalCheck(e.currentTarget.checked);
                localStorage.check = e.currentTarget.checked
              }}
            />
          </div>

          <div className="text-center mt-3 w-100 d-grid">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              ref={submitRef}
              disabled={isSubmitting}
              onClick={() => {
                window.gtag('event', "sign_in_email")
              }}
            >
              {t("Sign in")}
            </Button>
          </div>

          <div
            className="mt-4 d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <p>
              {t("No Account?")}
              <Link to="/auth/sign-up"> {t("Sign Up")}</Link>
            </p>
            <p>
              <Link to="/auth/forgot-password">{t("Forgot password?")}</Link>
            </p>
          </div>

          <div className="w-100 d-flex">
            {google_clientId && (
              <Button
                className="w-100"
                style={{ marginRight: "0.5rem" }}
                type="submit"
                variant="outline-primary"
                size="lg"
                onClick={(event) => {
                  window.gtag('event', "sign_in_google");
                  event.preventDefault();
                  setSocialIn(true);
                  googleLogin();
                }}
              >
                <img
                  alt="Google"
                  src={iconGoogle}
                  width="22"
                  style={{ marginRight: "0.4rem" }}
                />
                {t("Login with Google")}
              </Button>
            )}
            {facebook_fields && (
              // <FacebookProvider appId={facebook_appId}>
              <FacebookButton callback={responseFacebook} scopes={facebook_fields} setSocialIn={setSocialIn} />
              // </FacebookProvider>
            )}
            {/* {(lastResponse) && (<FacebookContext lastResponse={lastResponse} setSocialIn={setSocialIn} socialResponse={socialResponse} setResponse={setResponse} />)} */}
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
