import React from "react";
import { useTable, useSortBy } from "react-table";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import Scrollbar from "../Scrollbar";

const defaultPropGetter = () => ({});
const defaultRendererGetter = () => null;

const ColumnSortingTable = ({
  id,
  columns,
  data,
  isHidden,
  wheelPropagation,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  getCellRenderer = defaultRendererGetter,
  getCellRendererProps = defaultPropGetter,
}) => {

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <div
      className="ps-dark ps-overflow-hidden"
      style={{ height: isHidden ? `${(document.body.clientHeight - 240) * 100 / document.body.clientHeight}vh` : null }}
    >
      <Scrollbar wheelPropagation={wheelPropagation}>
        <Table bordered {...getTableProps()} id={id}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{ borderTop: 0 }}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps([
                      column.getSortByToggleProps(),
                      getColumnProps(column),
                      getHeaderProps(column),
                    ])}

                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                        ) : (
                          <FontAwesomeIcon icon={faSortDown} className="ms-2" />
                        )
                      ) : (
                        <FontAwesomeIcon icon={faSort} className="ms-2" />
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps(getRowProps(row))}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps([getCellProps(cell)])}>
                      {cell.render(
                        getCellRenderer(cell) || "Cell",
                        getCellRendererProps(cell)
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Scrollbar>
    </div >
  );
};

export default ColumnSortingTable;
