/*eslint no-template-curly-in-string: "off"*/
/*eslint no-useless-computed-key: "off"*/
import { Case, Names } from "./languages";

const uk = {
  translation: {
    // языки
    [Names.en]: "English - Англійська",
    [Names.uk]: "Українська",
    // валюта
    ["USD"]: "долар США",
    ["N USD"]: "${1} доларів США",
    // счетчики
    ["K"]: "${1} тис",
    ["M"]: " ${1} млн",
    ["N days"]: "${1} днів",
    ["1 month"]: "1 місяць",
    ["N months"]: "${1} місяців",
    ["/mo"]: "${1}/міс",
    ["Price /mo"]: "${1}${2}/міс",
    ["Regular Price /mo"]: "Звичайна ціна: ${1}${2}/міс",
    // логин
    ["Welcome back"]: "Ласкаво просимо назад, ${1}",
    ["Sign In"]: "Увійти",
    ["Sign in"]: "Увійти",
    ["Sign in to your account"]: "Увійдіть у свій акаунт",
    ["Sign in to your account to continue"]:
      "Увійдіть у свій акаунт, щоб продовжити",
    ["Your account has not been confirmed"]:
      "Ваш акаунт не підтверджено. Будь ласка, перевірте свою електронну пошту.",
    ["Must be a valid email"]: "Повинна бути дійсна електронна пошта",
    ["Email"]: "Електронна пошта",
    ["Enter your email"]: "Введіть свою електронну пошту",
    ["Password"]: "Пароль",
    ["Enter your password"]: "Введіть ваш пароль",
    ["Remember me next time"]: "Згадайте мене наступного разу",
    ["No Account?"]: "Немає акаунту?",
    ["Forgot password?"]: "Забули пароль?",
    ["Login with Google"]: "Увійдіть через Google",
    ["Login with Facebook"]: "Увійти через Facebook",
    ["Sign up with Google"]: "Зареєструйтеся через Google",
    ["Sign up with Facebook"]: "Зареєструйтеся через Facebook",
    ["Login or password incorrect."]: "Логін або пароль неправильний.",
    // регистрация
    ["Sign Up"]: "Зареєструватися",
    ["Sign up"]: "Зареєструватися",
    ["Already have an account?"]: "Вже є аккаунт?",
    ["Login here"]: "Увійдіть тут",
    ["You’re sign up!"]:
      "Ви зареєструвалися! Будь ласка, перевірте свою електронну пошту на підтвердження, яке ми вам щойно надіслали.",
    ["Must be at least N characters"]: "Має містити не менше ${1} символів",
    ["Must contain only Latin characters"]: "Має містити лише латинські символи, цифри, пробіли та символи _()",
    ["Password must contain"]:
      "Має містити лише латинські символи, а також принаймні одну цифру, одну велику та одну малу літеру",
    ["Both password need to be the same"]:
      "Обидва паролі мають бути однаковими",
    ["First name"]: "Ім'я",
    ["Last name"]: "Прізвище",
    ["Email address"]: "Адреса електронної пошти",
    ["Confirm password"]: "Підтвердіть пароль",
    ["By clicking the Sign Up button"]:
      "Натискаючи кнопку «Зареєструватися», ви погоджуєтесь з нашими ${1} та ${2}",
    ["Terms & Conditions"]: "Положення та умови",
    ["Terms & Conditions" + Case.Instrumental]: "Положеннями та умовами",
    ["Privacy Policy"]: "Політика конфіденційності",
    ["Privacy Policy" + Case.Instrumental]: "Політикою конфіденційності",
    ["Email confirmation"]: "Підтвердження електронної пошти",
    ["Please wait for the operation to complete"]:
      "Будь ласка, дочекайтеся завершення операції",
    ["Please, fill in the form to complete the registration"]:
      "Будь ласка, заповніть форму, щоб завершити реєстрацію",
    ["Company name"]: "Назва компанії",
    ["Business website URL"]: "URL-адреса бізнес-сайту",
    ["Number of employees"]: "Кількість працівників",
    ["Industry"]: "Галузь промисловості",
    ["Your position"]: "Ваша позиція",
    ["Phone number"]: "Номер телефону",
    ["Save changes"]: "Зберегти зміни",
    ["Complete"]: "Завершити",
    ["Please check your email"]: "Будь ласка, перевірте свою електронну пошту",
    ["Reset Password"]: "Скинути пароль",
    ["We have sent you an email with further instructions"]:
      "Ми надіслали вам електронний лист із подальшими інструкціями. Будь ласка, дотримуйтесь інструкцій, щоб змінити пароль.",
    ["We have sent you an email with further instructions.delete"]:
      "Ми надіслали вам електронний лист із подальшими інструкціями. Будь ласка, дотримуйтесь інструкцій, щоб видалити свій обліковий запис.",
    ["Email Address"]: "Адреса електронної пошти",
    ["Enter your email to reset your password"]:
      "Введіть свою електронну адресу, щоб скинути пароль",
    ["Remember your password?"]: "Пам'ятайте свій пароль?",
    ["Upload"]: "Завантажити",
    ["Display name"]: "Відображуване ім'я",
    ["Your changes have been successfully saved"]:
      "Ваші зміни успішно збережено",
    ["Note"]: "Примітка",
    ["Click on the message to see More Details"]:
      "Натисніть на повідомлення, щоб переглянути докладнішу інформацію",
    ["More details"]: "Детальніше",
    ["Connection timeout"]: "Тайм-аут з’єднання",
    ["Reset your password"]: "Скинути пароль.",
    ["After deleting your account"]:
      "Після видалення облікового запису ви не зможете ввійти за допомогою цієї електронної пошти, доки не зареєструєтеся знову",
    ["Confirm deletion"]: "Підтвердження видалення",
    // 404, 500 Error
    ["Error"]: "Помилка",
    ["Page not found"]: "Сторінку не знайдено.",
    ["The page you are looking for might have been removed"]:
      "Можливо, сторінку, яку ви шукаєте, видалено.",
    ["Return to website"]: "Повернутися на веб-сайт",
    ["Internal server error"]: "Внутрішня помилка сервера.",
    ["The server encountered something unexpected"]:
      "Сервер зіткнувся з чимось неочікуваним, що не дозволило йому виконати запит.",
    // профиль
    ["Account Settings"]: "Налаштування аккаунта",
    ["Profile"]: "Профіль",
    ["Details"]: "Деталі",
    ["User Profile"]: "Профіль користувача",
    ["Additional Details"]: "Додаткові деталі",
    ["Change Password"]: "Змінити пароль",
    ["Delete Account"]: "Видалити аккаунт",
    ["Settings"]: "Налаштування",
    ["Payment history has been deleted"]: "Історія платежів видалена.",
    ["This feature is only available for test"]:
      "Ця функція доступна лише для тестування.",
    ["Logout"]: "Вийти",
    // уведомления
    ["Account details required"]: "Потрібні додаткові дані акаунту",
    ["Please fill in all fields in the form"]:
      "Будь ласка, заповніть усі поля у формі.",
    ["Notifications"]: "Сповіщення",
    ["You have no notifications"]: "У вас немає сповіщень",
    ["Reminder"]: "Нагадування",
    // сеть
    ["Connection error"]: "Помилка з'єднання",
    ["No data after loading"]: "Немає даних після завантаження",
    ["Unsupported response data format"]:
      "Непідтримуваний формат даних відповіді",
    // разное
    ["Page"]: "Сторінка",
    ["of"]: "з",
    ["N"]: "№",
    ["Select your service"]: "Оберіть свій сервіс",
    ["Please, choose the file"]: "Будь ласка, виберіть файл",
    ["Please, choose the .zip file"]: "Будь ласка, виберіть архів .zip",
    ["There should be 4 files in the archive!"]: "В архіві має бути 4 файли .dcm!",
    ["The archive contains processed files!"]: "Архів містить оброблені файли!",
    ["Close"]: "Закрити",
    ["Usage Agreement"]: "Угода про використання",
    ["Agree"]: "Я згоден",
    ["Quit"]: "Вийти",
    ["sec"]: "сек.",
    ["Refresh"]: "Оновити",
    ["Send a request"]: "Надіслати запит",
    ["Ask me later"]: "Запитати пізніше",
    ["Info"]: "Інформація",
    ["Last"]: "Останні",
    // Analytics
    ["Analytics"]: "Аналітика",
    ["Today"]: "Сьогодні",
    ["Yesterday"]: "Вчора",
    ["Last Week"]: "Минулий тиждень",
    ["Last Month"]: "Минулий місяць",
    ["Last Year"]: "Минулий рік",
    ["Custom"]: "На замовлення",
    ["Custom Date"]: "Період",
    ["Total N"]: "Всього: ${1}",
    ["Cases per month"]: "Справ на місяць",
    ["Total cases by month"]: "Усього справ по місяцях",
    /* Medical Services */
    ["BCD"]: "Breast Cancer Detection",
    ["Services"]: "Сервіси",
    ["Cases"]: "Справи",
    ["Number of cases uploaded in the specified period"]: "Кількість справ, завантажених за вказаний період.",
    ["Using services"]: "Використання сервісів",
    ["Number of cases for each service"]: "Кількість справ для кожного сервісу за вказаний період.",
    /* BCD */
    ["Example archive"]: "Приклад архіву",
    ["Example files"]: "Приклади файлів",
    ["Archive Name"]: "Назва_Архіву.zip",
    ["DateTime"]: "Дата і час",
    ["Case ID"]: "ID справи",
    ["Delay"]: "Час обробки",
    ["Send"]: "Надіслати",
    ["History"]: "Історія",
    ["Please be advised that we do not retain uploaded data as files."]: "Зверніть увагу, що ми не зберігаємо завантажені дані у вигляді файлів.",
    ["Upload the archive in .zip format."]: "Завантажте архів у форматі .zip.",
    ["You can only upload 1 ..."]: "Ви можете обрати лише 1 архів за раз. Це повинен бути архів у форматі .zip, у якому знаходиться 4 файли формату .dcm з 4 проєкціями.",
    ["Anonymize metadata"]: "Анонімізація метаданих",
    ["Data storage policy"]: "Політика зберігання даних",
    ["Click to read more"]: "Натисніть, щоб прочитати більше",
    ["We would like to inform you about our updated data storage..."]: "Повідомляємо вам про нашу оновлену політику зберігання даних щодо комп’ютерної діагностики раку молочної залози. У Qudata ми надаємо пріоритет вашій конфіденційності та безпеці.\nЗверніть увагу, що ми не зберігаємо завантажені дані у вигляді файлів. Наша система розроблена для обробки та аналізу наданої інформації виключно для отримання результатів справи. Відповідно до наших зобов’язань щодо безпеки даних ми гарантуємо, що ваші завантажені дані не зберігаються на наших серверах.\nКрім того, користувачам пропонується завантажувати лише результати останніх справ. Наша система оптимізована для обробки та надання інформації на основі найновішої інформації, спрощуючи процес діагностики та мінімізуючи вимоги до пам’яті.",
    ["You don't have a history"]: "У вас немає історії.",
    ["Welcome to our online platform for computerized..."]: "Ласкаво просимо на онлайн-платформу комп’ютерної діагностики раку молочної залози. Ця система розроблена лише для демонстрації і не повинна використовуватися для діагностики будь-якого захворювання.\nНаша платформа демонструє роботу програми з аналізу рентгенівських знімків для виявлення раку молочної залози за допомогою штучного інтелекту . Ми прагнемо ознайомити медичних працівників з інноваційними методами та технологіями, що використовуються в галузі діагностики раку молочної залози. Зверніть увагу, що інформація, представлена на цій платформі, не замінює професійну медичну консультацію, діагностику чи лікування.",
    ["Please ensure you are archiving x-rays..."]: "Будь ласка, переконайтеся, що ви архівуєте наступні чотири файли, що містять рентгенівські зображення (мамограми), перш ніж надсилати:",
    ["You're out of tokens"]: "У Вас закінчилися спроби",
    ["You have N tokens left"]: "У Вас залишилось ${1} спроб",
    ["Access denied"]: "Доступ заборонено",
    ["Select file"]: "Виберіть файл",
    ["File is not selected"]: "Файл не вибрано",
    ["You have more entries in history"]: "У вас є більше записів в історії.",
    /* Reports */
    ["Activity Report"]: "Звіт про діяльність",
    ["Admin Reports"]: "Звіти адміністратора",
    ["Title"]: "Назва",
    ["Users"]: "Користувачі",
    ["Download CSV"]: "Завантажити CSV",
    ["Search"]: "Пошук",
    ["Enter company name..."]: "Введіть назву компанії...",
    ["company"]: "ID",
    ["title"]: "Назва",
    ["users"]: "Користувачі",
    ["cases"]: "Выпадки",
    // Закончились токены
    ["We would like to inform you that the trial period..."]: "Повідомляємо, що ваш пробний період тестування сервісу обробки рентгенівських знімків завершився. Ми сподіваємося, що 10 спроб допомогли вам оцінити можливості нашої діагностичної системи.\nЯкщо ви бажаєте й надалі користуватися нашим сервісом та безперешкодно інтегрувати вашу медичну установу з API QuData, просимо зв’язатися з нами електронною поштою – $lsinfo@qudata.com$le. Наша команда допоможе пройти процес інтеграції та відповість на будь-які ваші запитання.\nНа знак вдячності за вашу участь у тестуванні сервісу QuData, надалі у вас залишиться 1 спроба на день для обробки рентгенівських знімків з метою продовження тестування нашого сервісу.\nДякуємо, що обрали QuData для діагностики раку молочної залози. Ми раді допомогти вам у цій важливій справі.",
  },
};

export default uk;
