import { Button, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "../hooks/useLocalization";

const ShowInfoModal = ({ open, setOpen, title, text = "" }) => {
    const { t } = useTranslation();
    const onClose = async () => {
        setOpen(false);
    }
    return (
        <Modal show={open} onHide={onClose} enforceFocus={false}>
            <Helmet title={title || t("Info")} />
            <Modal.Header closeButton>{title || t("Info")}</Modal.Header>
            <Modal.Body className="text-center m-3">
                {text.split("\n").map((text, i) =>
                    <p key={i} style={{ textAlign: "justify" }}>{text}</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {t("Close")}
                </Button>{" "}
            </Modal.Footer>
        </Modal>
    );
};

export default ShowInfoModal;
