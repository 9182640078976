import React, { useCallback, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
  Button,
} from "react-bootstrap";
import {
  parseAnalytics,
  parseServices,
  parsePerMonth,
  DATE_FORMAT,
} from "./utils";
import { RefreshCw, RotateCw } from "react-feather";
import useAnalytics from "../../../hooks/useAnalytics";
import Navigation from "../../Navigation";
import RefreshAlert from "../../dashboard/RefreshAlert";
import {
  createInterval,
  FILTERS,
  dateToString,
  INTERVAL,
} from "../../../contexts/dashboard/filters";
import AntiSpam from "../../../utils/spam";
import useUI from "../../../hooks/useUI";
//import logger from "../../../utils/logger";
import ReportAnalyticsCard from "./cards/ReportAnalyticsCard";
import ReportServicesCard from "./cards/ReportServicesCard";
import ReportMonthCountCard from "./cards/ReportMonthCountCard";
import { useTranslation } from "../../../hooks/useLocalization";
import useAuth from "../../../hooks/useAuth";

import Thread from "../../../utils/thread";

const CHART_TYPE = {
  ANALYTICS: "analytics",
  SERVICES: "services",
  PERMONTH: "perMonth",
};

const antiSpam = new AntiSpam();
const MainThread = new Thread(4000);

const Analytics = () => {
  //logger.render(Analytics);
  const { i18n, t } = useTranslation();
  const { useOptionTemp } = useUI();
  const { TODAY, YESTERDAY, LAST_WEEK, LAST_MONTH, CUSTOM } = INTERVAL;
  const { filters = {}, reports = {}, isLoading = {}, loadingError, refreshReports, isInitialized } = useAnalytics();

  const { isAuthenticated } = useAuth();

  const { analytics, services, perMonth } = reports;

  const [navDate, setNavDate] = useOptionTemp("navDate", LAST_MONTH);
  const [intervalValues, setIntervalValues] = useOptionTemp(
    "intervalValues",
    createInterval(LAST_MONTH)
  );
  const intervalHashes = useMemo(
    () =>
      intervalValues.map(
        (dates) =>
          `${dates[0] && dates[0].getTime()},${dates[1] && dates[1].getTime()}`
      ),
    [intervalValues]
  );

  const options = useCallback(
    (type, chartName) => {
      const [from, to] =
        navDate === INTERVAL.CUSTOM ? intervalValues : createInterval(navDate);
      let useTime = (navDate === INTERVAL.TODAY || navDate === INTERVAL.YESTERDAY || (navDate === INTERVAL.CUSTOM && to - from < 60 * 60 * 24 * 2 * 1000));
      const [fromStr, toStr] = [dateToString(from, "", useTime), dateToString(to, "", useTime)];
      const period = fromStr === toStr ? fromStr : `${fromStr}-${toStr}`;
      const filename = `${chartName} ${period}`;
      const format =
        useTime
          ? DATE_FORMAT.HOUR
          : (type === CHART_TYPE.PERMONTH) ? DATE_FORMAT.MONTH : DATE_FORMAT.DATE;
      const locked = false; //checkPro(type);
      return { filename, format, chartName, locked };
    },
    [intervalValues, navDate]
  );

  const { language } = i18n;

  const chartAnalytics = useMemo(
    () => parseAnalytics(analytics, options(CHART_TYPE.ANALYTICS, t("Cases")), language),
    [analytics, options, t, language]
  );

  const chartMonthCount = useMemo(
    () => parsePerMonth(perMonth, options(CHART_TYPE.PERMONTH, t("Cases")), language),
    [perMonth, options, t, language]
  );

  const chartServices = useMemo(
    () => parseServices(services, options(CHART_TYPE.SERVICES, t("Services")), language),
    [services, options, t, language]
  );

  const getFilters = useCallback(
    ({ interval, intervalValues }) => ({
      [FILTERS.INTERVAL]: interval || filters[FILTERS.INTERVAL],
      [FILTERS.INTERVAL_VALUES]:
        intervalValues || filters[FILTERS.INTERVAL_VALUES],
    }),
    [filters]
  );

  const refresh = useCallback(() => {
    antiSpam.action(async () => {
      await refreshReports(
        getFilters({
          interval: navDate,
          intervalValues,
        })
      );
    });
  }, [
    refreshReports,
    getFilters,
    navDate,
    intervalValues,
  ]);

  useEffect(() => {
    return () => {
      MainThread.handler = null;
    }
  }, [])

  useEffect(() => {
    if (MainThread.handler == null) {
      refresh();
    }
  }, [refresh, MainThread.handler]);

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      MainThread.handler = async () => { refresh() };
    }
  }, [
    refresh,
    isInitialized,
    intervalHashes,
    isAuthenticated,
  ]);

  useEffect(() => {
    if (navDate !== CUSTOM) {
      setIntervalValues(createInterval(navDate));
    }
  }, [navDate, CUSTOM]);

  const onSelectInterval = (eventKey) => {
    setNavDate(eventKey);
    MainThread.handler = null;
  };

  const onFormDateChange = (event, index) => {
    const { value } = event.target;
    const newIntervalValues = [...intervalValues];
    newIntervalValues[index] = value ? new Date(value) : new Date();
    const [from = new Date(), to = new Date()] = newIntervalValues;
    if (from.getTime() > to.getTime()) from.setTime(new Date(to.getTime()));
    setIntervalValues(newIntervalValues);
    MainThread.handler = null;
  };

  const isLoadingAny = Object.keys(isLoading).some(
    (key) => isLoading[key] === true
  );

  const navItems = [
    { name: t("Today"), value: TODAY },
    { name: t("Yesterday"), value: YESTERDAY },
    { name: t("Last Week"), value: LAST_WEEK },
    { name: t("Last Month"), value: LAST_MONTH },
    { name: t("Custom Date"), value: CUSTOM },
  ];

  return (
    <>
      <Card className="flex-fill mb-4">
        <Card.Body className="analytics-header">
          <Row className="mt-n1">
            <Col className="d-flex gap-2 align-items-center">
              <Navigation
                activeKey={navDate}
                items={navItems}
                onSelect={onSelectInterval}
              />
              {navDate === CUSTOM && (
                <>
                  <Form.Control
                    type="date"
                    value={dateToString(intervalValues[0])}
                    onChange={(event) => onFormDateChange(event, 0)}
                    className="date-picker"
                    min="2023-01-01"
                  />
                  <Form.Control
                    type="date"
                    value={dateToString(intervalValues[1])}
                    onChange={(event) => onFormDateChange(event, 1)}
                    className="date-picker"
                    min="2023-01-01"
                  />
                </>
              )}
              <nav className="ms-auto" />
              <Button
                variant="outline-primary"
                className="shadow-sm"
                disabled={isLoadingAny}
                onClick={refresh}
              >
                {isLoadingAny ? (
                  <RotateCw className="feather fa-spin" />
                ) : (
                  <RefreshCw className="feather" />
                )}
                <span className="ms-2">{t("Refresh")}</span>
              </Button>
            </Col>
          </Row>
          <ProgressBar
            className={`position-absolute mt-2 ${isLoadingAny ? "" : "opacity-0"}`}
            striped animated variant="primary"
            now={isLoadingAny ? 90 : 0}
          />
        </Card.Body>
      </Card>
      {loadingError && (
        <Col md="12" className="mb-4">
          <RefreshAlert
            title={t("Reports")}
            error={loadingError}
            onRetry={refresh}
          />
        </Col>
      )}
      <Container fluid className="analytics p-0">
        <Row>
          <Col md="6">
            <ReportAnalyticsCard
              report={chartAnalytics}
              //proPlan={checkPro(CHART_TYPE.SESSIONS)}
              isLoading={isLoading.analytics}
            />
          </Col>
          <Col md="6">
            <ReportServicesCard
              report={chartServices}
              //proPlan={checkPro(CHART_TYPE.UNIQUE_USERS)}
              isLoading={isLoading.services}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid className="analytics p-0">
        <ReportMonthCountCard
          report={chartMonthCount}
          //proPlan={checkPro(CHART_TYPE.UNIQUE_USERS)}
          isLoading={isLoading.perMonth}
          height={150}
        />
      </Container>
    </>
  );
};

export default Analytics;
