import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Button, Card, ProgressBar, Form } from "react-bootstrap";
import { RefreshCw, RotateCw } from "react-feather";
import useAnalytics from "../../hooks/useAnalytics";
import { useTranslation } from "../../hooks/useLocalization";
import { uniqueArray } from "../../utils/data";
import logger from "../../utils/logger";
import AntiSpam from "../../utils/spam";
import ColumnSortingTable from "./ColumnSortingTable";
import Navigation from "../Navigation";

import {
    createInterval,
    dateToString,
    INTERVAL,
    FILTERS,
} from "../../contexts/dashboard/filters";

const antiSpam = new AntiSpam();

const ActivityReport = () => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const { filters = {}, getActivityReport, isInitialized } = useAnalytics();

    const getFilters = useCallback(
        ({ interval, intervalValues }) => ({
            [FILTERS.INTERVAL]: interval || filters[FILTERS.INTERVAL],
            [FILTERS.INTERVAL_VALUES]:
                intervalValues || filters[FILTERS.INTERVAL_VALUES],
        }),
        [filters]
    );

    const refresh = async () => {
        antiSpam.action(async () => {
            try {
                setLoading(true);
                const users = await getActivityReport(
                    getFilters({
                        interval: navDate,
                        intervalValues,
                    }));
                setUsersData(users || []);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        });
    }

    const headers = useMemo(
        () =>
            uniqueArray(
                ["company", "title", "users", "cases"].concat(
                    usersData.reduce(
                        (prev, item) => prev.concat(Object.keys(item).filter((key) => key !== "uid" && key !== "license")),
                        []
                    )
                )
            ),
        [usersData]
    );

    const tableColumns = headers.map((name) => ({
        Header: t(name),
        accessor: name,
    }));

    const tableData = usersData.map((val) =>
        Object.assign(
            {},
            ...Object.keys(val).map((key) => ({
                [key]:
                    ["N", "Title", "Users", "Cases"].indexOf(key) < 0 &&
                        val[key] &&
                        typeof val[key] === "object"
                        ? JSON.stringify(val[key])
                        : key === "websiteURL"
                            ? val[key].replace(/\//g, `/\n`)
                            : val[key],
            }))
        )
    );

    const { TODAY, YESTERDAY, LAST_WEEK, LAST_MONTH, CUSTOM } = INTERVAL;

    const [navDate, setNavDate] = useState(LAST_MONTH);

    const [intervalValues, setIntervalValues] = useState(createInterval(LAST_MONTH));

    const navItems = [
        { name: t("Today"), value: TODAY },
        { name: t("Yesterday"), value: YESTERDAY },
        { name: t("Last Week"), value: LAST_WEEK },
        { name: t("Last Month"), value: LAST_MONTH },
        { name: t("Custom Date"), value: CUSTOM },
    ];

    const onSelectInterval = (eventKey) => {
        setNavDate(eventKey);
    };

    const onFormDateChange = (event, index) => {
        const { value } = event.target;
        const newIntervalValues = [...intervalValues];
        newIntervalValues[index] = value ? new Date(value) : new Date();
        const [from = new Date(), to = new Date()] = newIntervalValues;
        if (from.getTime() > to.getTime()) from.setTime(new Date(to.getTime()));
        setIntervalValues(newIntervalValues);
    };

    useEffect(() => {
        if (isInitialized) refresh();
    }, [isInitialized, intervalValues, navDate])

    return (
        <>
            <Card className="wordpress-header">
                <Card.Header className="d-flex">
                    <Navigation
                        activeKey={navDate}
                        items={navItems}
                        onSelect={onSelectInterval}
                    />
                    {navDate === CUSTOM && (
                        <>
                            <Form.Control
                                type="date"
                                value={dateToString(intervalValues[0])}
                                onChange={(event) => onFormDateChange(event, 0)}
                                className="date-picker ms-3"
                                min="2023-01-01"
                            />
                            <Form.Control
                                type="date"
                                value={dateToString(intervalValues[1])}
                                onChange={(event) => onFormDateChange(event, 1)}
                                className="date-picker ms-2"
                                min="2023-01-01"
                            />
                        </>
                    )}
                </Card.Header>
            </Card>
            <Card className="wordpress-header">
                <Card.Header className="d-flex align-items-center">
                    <Card.Title tag="h5" className="mb-0 flex-1">
                        <div className="d-flex align-items-center">
                            <span className="me-2" style={{ cursor: "default" }}>{t("Search") + ":"}</span>
                            <Form.Control
                                type="search"
                                style={{ maxWidth: "300px" }}
                                placeholder={t("Enter company name...")}
                                disabled
                            />
                        </div>
                    </Card.Title>
                    <Button
                        variant="outline-primary"
                        className="shadow-sm me-2"
                        onClick={downloadCSV}
                    >{t("Download CSV")}
                    </Button>
                    <Button
                        variant="outline-primary"
                        className="shadow-sm"
                        disabled={isLoading}
                        onClick={refresh}
                    >
                        {isLoading ? (
                            <RotateCw className="feather fa-spin" />
                        ) : (
                            <RefreshCw className="feather" />
                        )}
                        <span className="ms-2">{t("Refresh")}</span>
                    </Button>
                </Card.Header>
            </Card>
            <Card>
                <ProgressBar
                    className={`my-2 mx-3 ${isLoading ? "" : "opacity-0"}`}
                    striped
                    animated
                    variant="primary"
                    now={isLoading ? 90 : 0}
                    style={{ height: "3px" }}
                />
                <ColumnSortingTable isHidden={true} columns={tableColumns} data={tableData} wheelPropagation={true} id="activity-report-table" />
            </Card>
        </>
    )
}

const downloadCSV = () => {
    let CSV = tableToCSV("activity-report-table");
    if (!CSV) return
    let temp_link = document.createElement('a');
    temp_link.download = "UsersActivityReport.csv";
    let universalBOM = "\uFEFF";
    temp_link.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + CSV);
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    temp_link.click();
    document.body.removeChild(temp_link);
}

const tableToCSV = (elementID) => {
    let csv_data = [];
    let element = document.getElementById(elementID)

    if (!element) {
        logger.error(`Element not found (${elementID})`)
        return null
    }

    let rows = element.getElementsByTagName('tr');

    for (let i = 0; i < rows.length; i++) {
        let cols = rows[i].querySelectorAll('td,th');
        let csvrow = [];

        for (let j = 0; j < cols.length; j++) {
            let data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
            data = data.replace(/"/g, '""');
            csvrow.push(data);
        }

        csv_data.push(csvrow.join(","));
    }
    csv_data = csv_data.join('\n');
    return "sep=,\n" + csv_data;
}

export default ActivityReport;
