import React, { useEffect, useReducer } from "react";
import api from "../../net/api";
import useAuth from "../../hooks/useAuth";
import { createInterval, FILTERS, INTERVAL } from "./filters";

import { useTranslation } from "../../hooks/useLocalization";

const INITIALIZE = "INITIALIZE";
const REPORTS_LOADING = "REPORTS_LOADING";
const FILTERS_UPDATED = "FILTERS_UPDATED";
const REPORTS_UPDATED = "REPORTS_UPDATED";

const emptyFilters = () => ({});

const emptyReports = () => ({
  analytics: [],
  services: [],
  history: [],
  perMonth: [],
  activity: []
});

const emptyLoading = () => ({
  analytics: false,
  services: false,
  history: false,
  perMonth: false,
  activity: false
});

const initialState = {
  filters: emptyFilters(),
  reports: emptyReports(),
  isLoading: emptyLoading(),
  loadingError: null,
  isInitialized: false,
};

const AnalyticsReducer = (state, action) => {
  // logger.dev("[AnalyticsReducer]", action.type, action.payload);
  const {
    filters,
    reports,
    isLoading,
    loadingError,
    isInitialized,
  } = action.payload || {};

  switch (action.type) {
    case INITIALIZE:
      if (!isInitialized) {
        state = {
          ...state,
          filters: emptyFilters(),
          reports: emptyReports(),
        };
      }
      return {
        ...state,
        isLoading: emptyLoading(),
        loadingError,
        isInitialized: true,
      };
    case REPORTS_LOADING:
      for (let key in isLoading) state.isLoading[key] = isLoading[key];
      return { ...state, loadingError };
    case FILTERS_UPDATED:
      for (let key in filters) state.filters[key] = filters[key];
      return { ...state };
    case REPORTS_UPDATED:
      for (let key in reports) state.reports[key] = reports[key];
      return { ...state };
    default:
      return state;
  }
};

const AnalyticsContext = React.createContext({});

function AnalyticsProvider({ children }) {
  const { secureAPIRequest, user } = useAuth();
  const [state, dispatch] = useReducer(AnalyticsReducer, initialState);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch({
      type: INITIALIZE,
      payload: { ...initialState },
    });
  }, [])

  // Loading status
  // ------------------------
  const dispatchLoading = (isLoading, loadingError) =>
    dispatch({
      type: REPORTS_LOADING,
      payload: { isLoading, loadingError },
    });

  // Refresh reports
  // ------------------------
  const refreshReports = async (filters) => {
    try {
      dispatchLoading({ all: true });
      getAnalytics(filters);
      getServices(filters);
      getPerMonth();
      dispatchLoading({ all: false });
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ all: false }, e);
    }
  };

  // Sessions
  // ------------------------
  const getAnalytics = async (filters) => {
    try {
      dispatchLoading({ analytics: true });
      setFilters(filters);
      const response = await buildRequest(
        "analytics", api.REPORTS.ANALYTICS, {},
        filters,
        {
          [FILTERS.INTERVAL]: true
        }
      );
      dispatchLoading({ analytics: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ analytics: false }, e);
    }
  };

  const getActivityReport = async (filters) => {
    try {
      dispatchLoading({ activity: true });
      setFilters(filters);
      const response = await buildRequest(
        "activity", api.REPORTS.GET_ACTIVITY_REPORT, {},
        filters,
        {
          [FILTERS.INTERVAL]: true,
        }
      );
      dispatchLoading({ activity: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ activity: false }, e);
    }
  };

  const getPerMonth = async () => {
    try {
      dispatchLoading({ analytics: true });
      const filters = {
        interval: INTERVAL.LAST_YEAR,
        interval_values: []
      }
      setFilters(filters);
      const response = await buildRequest(
        "perMonth", api.REPORTS.ANALYTICS, {},
        filters,
        {
          [FILTERS.INTERVAL]: true
        }
      );
      dispatchLoading({ analytics: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ analytics: false }, e);
    }
  };

  const getServices = async (filters) => {
    try {
      dispatchLoading({ services: true });
      setFilters(filters);
      const response = await buildRequest(
        "services", api.REPORTS.SERVICES, {},
        filters,
        {
          [FILTERS.INTERVAL]: true
        }
      );
      dispatchLoading({ services: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ services: false }, e);
    }
  };

  const addHistory = async (value) => {
    const response = { data: { rows: value } }
    const reportsKey = "history";
    const request = api.REPORTS.HISTORY;
    const filters = {};
    const reportsValue = await parseResponse(request, filters, response);
    const val = [...reportsValue, ...state.reports.history]
    dispatch({
      type: REPORTS_UPDATED,
      payload: { reports: { [reportsKey]: val } },
    });
  }

  const getHistory = async (filters) => {
    try {
      dispatchLoading({ history: true });
      const response = await buildRequest(
        "history", api.REPORTS.HISTORY, {},
        filters, { filter: filters }
      );
      dispatchLoading({ history: false });
      return response;
    } catch (e) {
      // logger.error(e);
      dispatchLoading({ history: false }, e);
    }
  };

  const buildRequest = async (reportsKey, request, params, filters, include = {}) => {
    const response = await secureAPIRequest(
      request,
      buildRequestParams(params, filters, { ...include, uid: true })
    );
    switch (reportsKey) {
      case "perMonth":
        request = reportsKey
        break;
      default:
    }
    const reportsValue = await parseResponse(request, filters, response);
    dispatch({
      type: REPORTS_UPDATED,
      payload: { reports: { [reportsKey]: reportsValue } },
    });
    return reportsValue;
  };

  const buildRequestParams = (params, filters = {}, include = {}) => {
    const requestFilters = [];

    if (include.uid) {
      requestFilters.push({
        field: "uid",
        value: user.uid,
      });
    }

    if (include[FILTERS.INTERVAL]) {
      let [from, to] = createInterval(
        filters[FILTERS.INTERVAL],
        filters[FILTERS.INTERVAL_VALUES]
      );
      requestFilters.push({
        type: FILTERS.STAMPTERVAL,
        from: from.getTime(),
        to: to.getTime(),
      });
    }

    if (include.filter) {
      requestFilters.push(include.filter);
    }

    params.filters = requestFilters;
    return params;
  };

  const setFilters = (filters = {}) => {
    dispatch({ type: FILTERS_UPDATED, payload: { filters } });
    for (let key in filters) {
      state.filters[key] = filters[key];
    }
    return filters;
  };

  const parseResponse = async (url, filters, response) => {
    // logger.dev("[AnalyticsContext] parseResponse", url, response);

    const { data } = response;
    if (!data) {
      throw new Error(`No data after loading`);
    }

    const { rows } = data;
    if (!Array.isArray(rows)) {
      throw new Error(`Unsupported response data format`);
    }

    const localTime = (rows) => {
      return rows
        .filter(({ time }) => typeof time === "number")
        .map((row) => ({
          ...row,
          time: new Date(row.time).toLocaleString(),
        }));
    };

    const ISOTime = (rows) => {
      return rows
        .filter(({ time }) => typeof time === "number")
        .map((row) => ({
          ...row,
          time: new Date(row.time).toISOString(),
        }));
    };

    const rangeTime = (rows) => {
      const interval = filters[FILTERS.INTERVAL];
      const intervalValues = filters[FILTERS.INTERVAL_VALUES];
      const [from, to] =
        interval === INTERVAL.CUSTOM
          ? intervalValues || []
          : createInterval(interval);
      from.setHours(15)
      if (from && to) {
        if (to.getTime() > Date.now()) {
          to.setTime(Date.now());
        }
        if (!rows.find(({ time }) => new Date(time).getTime() <= from
          //|| (new Date(time).getDate() == from.getDate() && new Date(time).getMonth() == from.getMonth() && new Date(time).getFullYear() == from.getFullYear())
        )) {
          rows.unshift({ time: from.toISOString(), count: 0 });
        }
        if (!rows.find(({ time }) => new Date(time).getTime() >= to
          //|| (new Date(time).getDate() == to.getDate() && new Date(time).getMonth() == to.getMonth() && new Date(time).getFullYear() == to.getFullYear())
        )) {
          rows.push({ time: to.toISOString(), count: 0 });
        }
      }
      return rows;
    };
    const rangeMonth = (rows) => {
      if (rows.length > 1) return rows;
      rows.unshift({ time: new Date().toISOString(), count: 0 });
      return rows;
    };

    const sortTime = (rows, reverse = 1) =>
      rows.sort(
        ({ time: a }, { time: b }) =>
          (new Date(a).getTime() - new Date(b).getTime()) * reverse
      );

    const getDay = (time) => {
      const interval = filters[FILTERS.INTERVAL];
      const intervalValues = filters[FILTERS.INTERVAL_VALUES];
      let dd = new Date(time);
      let date = '';
      switch (interval) {
        case INTERVAL.TODAY:
        case INTERVAL.YESTERDAY:
          date = dd.getFullYear() + "/" + (1 + dd.getMonth()) + "/" + dd.getDate() + " " + dd.getHours() + ":00:00";
          dd = new Date(date)
          break;
        case INTERVAL.LAST_YEAR:
          date = dd.getFullYear() + "/" + (1 + dd.getMonth())
          dd = new Date(date)
          break;
        case INTERVAL.CUSTOM:
          const [from, to] =
            interval === INTERVAL.CUSTOM
              ? intervalValues || []
              : createInterval(interval);
          if (to - from > 60 * 60 * 24 * 2 * 1000) {
            date = dd.getFullYear() + "/" + (1 + dd.getMonth()) + "/" + dd.getDate();
            dd = new Date(date).setHours(15);
          } else {
            date = dd.getFullYear() + "/" + (1 + dd.getMonth()) + "/" + dd.getDate() + " " + dd.getHours() + ":00:00";
            dd = new Date(date)
          }
          break;
        default:
          date = dd.getFullYear() + "/" + (1 + dd.getMonth()) + "/" + dd.getDate();
          dd = new Date(date).setHours(15);
      }
      return new Date(dd).toISOString();
    }

    const groupTime = (rows, append = true) => {
      let result = Object.groupBy(rows, ({ time }) => getDay(time));
      let res = [];
      Object.keys(result).forEach(function (key, index) {
        res.push({ time: key, count: result[key].length })
      });
      const interval = filters[FILTERS.INTERVAL];
      const intervalValues = filters[FILTERS.INTERVAL_VALUES];
      const [from, to] =
        interval === INTERVAL.CUSTOM
          ? intervalValues || []
          : createInterval(interval);
      if (append) {
        let len = res.length;
        for (let i = 0; i < len; i++) {
          let date = new Date(res[i].time);
          date.setDate(date.getDate() - 1);
          if (date > from)
            res.push({ time: date.toISOString(), count: 0 });
          date.setDate(date.getDate() + 2);
          if (date < to)
            res.push({ time: date.toISOString(), count: 0 });
        }
      }
      return res
    }

    const dropDuplicates = (rows, type = null) => {
      if (!type) {
        const interval = filters[FILTERS.INTERVAL];
        const intervalValues = filters[FILTERS.INTERVAL_VALUES];
        switch (interval) {
          case INTERVAL.TODAY:
          case INTERVAL.YESTERDAY:
            type = 'time'
            break;
          case INTERVAL.CUSTOM:
            const [from, to] =
              interval === INTERVAL.CUSTOM
                ? intervalValues || []
                : createInterval(interval);
            if (to - from > 60 * 60 * 24 * 2 * 1000) {
              type = 'time'
            } else {
              type = 'date'
            }
            break;
          default:
            type = 'date'
        }
      }

      let result = {};
      switch (type) {
        case 'date':
          rows.forEach(item => {
            let dt = new Date(item.time).toLocaleDateString();
            if (result[dt] !== undefined) {
              if (item.count > 0 && !result[dt]?.count) {
                result[dt] = item;
              } else {
                result[dt].count += item.count;
              }
            } else {
              result[dt] = item;
            }
          })
          rows = []
          for (let item in result)
            rows.push(result[item])
          return rows
        case 'time':
          rows.forEach(item => {
            let dt = new Date(item.time).toLocaleDateString() + '_' + new Date(item.time).getHours();
            if (result[dt] !== undefined) {
              if (item.count > 0 && !result[dt]?.count) {
                result[dt] = item;
              } else {
                result[dt].count += item.count;
              }
            } else {
              result[dt] = item;
            }
          })
          rows = []
          for (let item in result)
            rows.push(result[item])
          return rows
        default:
          result = rows;
      }
      return result;
    }

    switch (url) {
      // [{time: '', ***}]
      case api.REPORTS.ANALYTICS:
        let ss = sortTime(rangeTime(groupTime(ISOTime(rows))));
        return dropDuplicates(ss);
      case api.REPORTS.SERVICES:
        rows.forEach((item) => {
          item.service = (item.service) ? t(item.service.toUpperCase()) : item.service
        })
        return rows;
      case api.REPORTS.HISTORY:
        let tt = localTime(sortTime(rows, -1));
        tt.forEach((item) => {
          item.delay = item.delay.split('.')[0]
        })
        return tt
      case "perMonth":
        let dd = sortTime(rangeMonth(groupTime(ISOTime(rows), false)));
        return dropDuplicates(dd);
      case api.REPORTS.GET_ACTIVITY_REPORT:
        return rows
      default:
        return null;
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        ...state,

        getActivityReport,
        refreshReports,
        getAnalytics,
        getServices,
        getHistory,
        addHistory
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}

export { AnalyticsProvider, AnalyticsContext };
