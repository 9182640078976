import React, { useEffect, useReducer, useState } from "react";
import api from "../../net/api";
import useAuth from "../../hooks/useAuth";
import useSidebar from "../../hooks/useSidebar";
import useAnalytics from "../../hooks/useAnalytics";

const INITIALIZE = "INITIALIZE";

const initialState = {
    loadingError: null,
    isInitialized: true,
};

const ModulesReducer = (state, action) => {
    const {
        loadingError,
        isInitialized,
    } = action.payload || {};

    switch (action.type) {
        case INITIALIZE:
            if (!isInitialized) {
                state = { ...state, };
            }
            return {
                ...state,
                loadingError,
                isInitialized,
            };
        default:
            return state;
    }
};

const ModulesContext = React.createContext({});

function ModulesProvider({ children }) {
    const { setSidebarProps } = useSidebar();
    const { secureAPIRequest } = useAuth();
    const [state] = useReducer(ModulesReducer, initialState);

    const [isSubmitingProcess, setSubmittingProcess] = useState(false)
    const [isSubmitingResult, setSubmittingResult] = useState(null) // { hasError: true, errorMessage: "" }

    const [isFileResult, setFileResult] = useState(null)

    const convertBase64ToFile = (result) => {
        try {
            let fileName = "result-" + result.patient_id + ".zip";
            let bstr = atob(result.data);
            let n = bstr.length;
            let uint8Array = new Uint8Array(n);
            while (n--) {
                uint8Array[n] = bstr.charCodeAt(n);
            }
            return new File([uint8Array], fileName, { type: 'application/zip' });;
        } catch {
            return null
        }
    }

    useEffect(() => {
        setFileResult((isSubmitingResult?.data) ? convertBase64ToFile(isSubmitingResult) : null)
    }, [isSubmitingResult])

    const { addHistory } = useAnalytics();

    // Sidebar
    // ------------------------
    useEffect(() => {
        setSidebarProps({
            isLoading: false,
        });
    }, [setSidebarProps, state]);

    // Send File
    // ------------------------
    const send = async (props) => {
        const { request, data } = props;
        switch (request) {
            case api.MODULES.BCD:
                return await secureAPIRequest(api.MODULES.BCD, data, true, 1_800_000);
            default:
                throw new Error(`Unknown reports request (${request})`);
        }
    };

    const bcdSend = async (props) => {
        setSubmittingResult({ patient_id: props.patient_id })
        setSubmittingProcess(true)
        try {
            let result = await send({ request: api.MODULES.BCD, data: props })
            setSubmittingResult({ hasError: !result.data.result, data: result.data.data, info: result.data.info, patient_id: props.patient_id })
            await addHistory(result.data.info)
            setSubmittingProcess(false)
            return result
        } catch (error) {
            let message = '' + error;
            if (message.indexOf('status code 401') > -1) {
                setSubmittingResult({ hasError: true, errorMessage: 'Access denied', patient_id: props.patient_id })
            } else {
                setSubmittingResult({ hasError: true, errorMessage: error, patient_id: props.patient_id })
            }
            setSubmittingProcess(false)
            throw error;
        }
    }

    return (
        <ModulesContext.Provider
            value={{
                ...state,
                bcdSend,
                isSubmitingProcess,
                isSubmitingResult,
                isFileResult,
                convertBase64ToFile
            }}
        >
            {children}
        </ModulesContext.Provider>
    );
}

export { ModulesProvider, ModulesContext };
