/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import provider from "../../../net/provider";

//import useAnalytics from "../../../hooks/useAnalytics";

const NavListItemLink = ({ title, href, email = false, depth = 0, icon: Icon }) => {
  //const { CONSOLE_EVENTS } = useAnalytics();
  return (
    <li className="sidebar-item">
      <a
        target="_blank"
        rel="noreferrer"
        href={(!email) ? provider.httpURL(href) : 'mailto:' + href}
        activeclassname="active"
        className="sidebar-link d-flex align-items-center"
        onClick={async () => {
          //await CONSOLE_EVENTS.EV_Locate.send({ params: { href: href } });
        }}
      >
        {Icon && <Icon className="feather align-middle" />}{" "}
        <span
          className="align-middle d-inline-block text-truncate"
          style={{ maxWidth: "150px" }}
          depth={depth}
        >
          {title}
        </span>
      </a>
    </li>
  );
};

export default NavListItemLink;
