import React from "react";
import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { Badge, Spinner } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import Scrollbar from "../Scrollbar";
//import { NavLink } from "react-router-dom";
import { useTranslation } from "../../hooks/useLocalization";
//import { isMobile } from "../../utils/screen";

const Sidebar = ({ items, showFooter }) => {
  const { t } = useTranslation();
  const { sidebarProps, isOpen } = useSidebar();
  const { isLoading, plan } = sidebarProps;

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <a className="sidebar-brand" href="/">
          <Logo /> <span className="align-middle me-2">Qudata</span>
          {isLoading ? (
            <Spinner animation="border" variant="info" size="sm" className="ms-1" />
          ) : (
            plan && <Badge bg="info">{t(plan.title)}</Badge>
          )}
        </a>
        <div className="sidebar-upgrade" style={{ cursor: 'default' }} >
          <Badge bg="info" className="w-100 text-wrap p-2 mb-2 rounded-0">
            {t("Select your service")}
          </Badge>
        </div>
        <Scrollbar>
          <div className="h-0">
            <SidebarNav items={items} />
          </div>
        </Scrollbar>
        {!!showFooter && <SidebarFooter />}
      </div>
    </nav>
  );
};

export default Sidebar;
