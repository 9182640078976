import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../hooks/useLocalization";

const BreadcrumbHeader = ({ title, onTitle, subtitle }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Breadcrumb className="lead">
      <Breadcrumb.Item onClick={() => navigate("/dashboard#clear")}>
        {t("Services")}
      </Breadcrumb.Item>
      {/* <Breadcrumb.Item /> */}
      {(subtitle && (
        <>
          <Breadcrumb.Item onClick={() => onTitle && onTitle()}>
            {title}
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="text-dark font-weight-bold">
            {subtitle}
          </Breadcrumb.Item>
        </>
      )) || (
          <Breadcrumb.Item active className="text-dark font-weight-bold">
            {title}
          </Breadcrumb.Item>
        )}
    </Breadcrumb>
  );
};

export default BreadcrumbHeader;
