import React from "react";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import LandingLayout from "./layouts/Landing";

// Guards
import EntryGuard from "./components/guards/EntryGuard";
import AuthGuard from "./components/guards/AuthGuard";
import RoutesGuard from "./components/guards/RoutesGuard";

// Landing
import Landing from "./pages/landing/Landing";

// Pages
import Settings from "./pages/pages/Settings";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import SignUpVerify from "./pages/auth/SignUpVerify";
import SignUpFast from "./pages/auth/SignUpFast";
import SignXDeleteConfirm from "./pages/auth/SignXDeleteConfirm";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";

import Analytics from "./pages/dashboard/analytics/Analytics";
import BCDModule from "./pages/dashboard/modules/bcd/BCDModule";

const routes = [
  {
    path: "/",
    element: (
      <EntryGuard>
        <AuthGuard>
          <RoutesGuard path="/" redirect="account">
            <LandingLayout />
          </RoutesGuard>
        </AuthGuard>
      </EntryGuard>
    ),
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "dashboard",
    element: (
      <EntryGuard>
        <AuthGuard>
          <RoutesGuard path="dashboard" redirect="bcd">
            <DashboardLayout />
          </RoutesGuard>
        </AuthGuard>
      </EntryGuard>
    ),
    children: [
      {
        path: "bcd",
        element: <BCDModule />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
    ],
  },
  {
    path: "account",
    element: (
      <EntryGuard>
        <AuthGuard>
          <RoutesGuard path="account" redirect="settings">
            <DashboardLayout />
          </RoutesGuard>
        </AuthGuard>
      </EntryGuard>
    ),
    children: [
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "auth",
    element: (
      <EntryGuard>
        <RoutesGuard path="auth" redirect="sign-in">
          <AuthLayout />
        </RoutesGuard>
      </EntryGuard>
    ),
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "sign-up-verify",
        element: <SignUpVerify />,
      },
      {
        path: "sign-up-verify/:fast",
        element: <SignUpVerify />,
      },
      {
        path: "quick-sign-up",
        element: <SignUpFast />,
      },
      {
        path: "sign-xdelete-confirm",
        element: <SignXDeleteConfirm />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
